import { parseISO, isWithinInterval, isBefore, format } from 'date-fns';

import { calcExtremesTimeseriesOptions, calcExtremesAnnualOptions } from '../../utilities/charts/extremes-chart-options';
import updateOverlayFromR2 from '../../utilities/overlayUpdateFns';
import { TODAY } from '../../contexts/constants';
import { refreshThresholdChartData } from '../../utilities/extremes-utilities';

const TAB_NAME = 'Extreme Cold';
const DATA_NAME = 'mintMaxt';

const info = {
  name: TAB_NAME,
  subTabs: [
    {
      name: 'Min < 32°F'
    },{
      name: 'Max < 32°F'
    },
  ],
  chartInfo: {
    chartSelectorId: 'extremeColdChartSelector',
    charts: [{
      name: 'Cold Temperature Exceedance',
      func: (chartData, address, tabInfoContextState, selectedSubTab) => {
        const thresholdValue = tabInfoContextState.tabsSharedState.extremeColdThresholdSelector.value;
        let threshold = typeof thresholdValue === 'function' ? thresholdValue({ selectedSubTab })[0] : thresholdValue[0];
        return calcExtremesTimeseriesOptions(chartData, address, threshold, 'below', selectedSubTab.slice(0,3));
      }
    },{
      name: 'Occurrences in Season',
      func: (chartData, address, tabInfoContextState, ...rest) => calcExtremesAnnualOptions(chartData, address, tabInfoContextState.tabsSharedState.extremeColdSeasonBoundsSelector.value)
    }],
  },
  onChange: [{
    whenXChanges: ['selectedSubTab'],
    handleChange: ({ selectedSubTab }, _, { updateOverlayData }) => {
      const jsonFileName = `days_under_32_${selectedSubTab.split(' ')[0].toLowerCase()}.json`;
      const jsonFileDataKey = 'departures';
      return updateOverlayFromR2(jsonFileName, jsonFileDataKey)
        .then(d => () => updateOverlayData(d));
    },
    showLoading: true
  },{
    whenXChanges: ['extremeColdThresholdSelector', 'extremeColdSeasonBoundsSelector', 'rawChartData', 'selectedSubTab'],
    handleChange: ({ selectedTab, selectedSubTab, selectedLocation, tabsSharedState, rawChartData }, _, { setChartData }) => {
      if (
        selectedTab === TAB_NAME &&
        selectedLocation in rawChartData && DATA_NAME in rawChartData[selectedLocation]
      ) {
        let thresholds = tabsSharedState.extremeColdThresholdSelector.value;
        if (typeof thresholds === 'function') thresholds = thresholds({selectedSubTab})[0];
        let seasonBounds = tabsSharedState.extremeColdSeasonBoundsSelector.value;
        if (typeof seasonBounds === 'function') seasonBounds = seasonBounds();

        const dataIdx = selectedSubTab.includes('Min') ? 1 : 2;

        const { newChartData } = refreshThresholdChartData(rawChartData[selectedLocation][DATA_NAME], dataIdx, thresholds, seasonBounds, 'below', TODAY);
        return () => setChartData(newChartData);
      }
      return () => {};
    },
    showLoading: true
  }],
  mapInfo: {
    overlayKeySelectorId: 'departureSelector',
    regionsSelectorId: 'regionSelector'
  },
  optionsPanel: {
    map: [{
      component: 'listSelector',
      id: 'extreme-cold-regions',
      useSharedState: 'regionSelector'
    },{
      component: 'departureSelector',
      id: 'extreme-cold-comparison',
      useSharedState: 'departureSelector'
    }],
    chart: [{
      component: 'listSelector',
      id: 'extreme-cold-charts',
      useSharedState: 'extremeColdChartSelector',
      props: {
        listTypeSingular: 'Chart',
        listTypePlural: 'Charts',
        showAll: false,
        showNone: false
      }
    },{
      component: 'thresholdSelector',
      id: 'extreme-cold-threshold-selector',
      useSharedState: 'extremeColdThresholdSelector',
      props: {
        columnHeading: 'Days Under',
        minNum: 1,
        maxNum: 1,
        minValue: -200,
        maxValue: 200,
        step: 1,
        colors: ['black'],
        label: () => 'Change Threshold',
        showReset: true
      }
    },{
      component: 'seasonBoundsSelector',
      id: 'extreme-cold-season-bounds-selector',
      useSharedState: 'extremeColdSeasonBoundsSelector'
    }]
  },
  componentFns: {
    title: ({selectedSubTab, display, overlayDataDate, chartData}) => {
      try {
        let sdate, edate;
        if (display === 'map') {
          const dateObj = parseISO(overlayDataDate);
          const isCurrentSeasonYear = isBefore(dateObj, new Date(dateObj.getFullYear(), 6, 1));
          const syear = dateObj.getFullYear() - (isCurrentSeasonYear ? 1 : 0);
          sdate = format(new Date(syear, 6, 1), 'LLL do, yyyy');
          edate = format(dateObj, 'LLL do, yyyy');
        } else {
          sdate = format(parseISO(chartData.timeseries.dates[0]), 'LLL do, yyyy');
          edate = format(parseISO(chartData.timeseries.dates.slice(-1)[0]), 'LLL do, yyyy');
        }

        return {
          type: `Days With ${selectedSubTab.slice(0,3)} Under ${selectedSubTab.slice(6,8)}`,
          sdate,
          edate
        };
      } catch {
        return null;
      }
    }
  },
  textPanelInfo: [{
    name: 'How To Use Tool',
    markdownFilename: 'how-to-use_default.md'
  },{
    name: 'About The Data',
    markdownFilename: 'about-the-data_default.md'
  }]
};
export default info;

export const extremeColdSharedStateDefinitions = {
  'extremeColdChartSelector': {
    options: info.chartInfo.charts,
    initValue: info.chartInfo.charts
  },
  'extremeColdThresholdSelector': {
    onNavChange: (oldValue, { newSubTab }) => {
      const newValue = parseInt(newSubTab.slice(6,8));
      return isNaN(newValue) ? oldValue : [newValue];
    },
    initValue: ({ selectedSubTab }) => ([parseInt(selectedSubTab.slice(6,8))])
  },
  'extremeColdSeasonBoundsSelector': {
    persist: false,
    initValue: () => {
      const isCurrentSeasonYear = isWithinInterval(TODAY, {
        start: new Date(TODAY.getFullYear(), 0, 1),
        end: new Date(TODAY.getFullYear(), 5, 31)
      });
      const startYear = TODAY.getFullYear() - (isCurrentSeasonYear ? 1 : 0);
      
      return [`${startYear}-07-01`, format(TODAY, 'yyyy-MM-dd')];
    }
  }
};