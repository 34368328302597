import React, { useContext, useState } from 'react';

import { TabInfoContext } from '../../contexts/tab-info.context';
import { GlobalContext } from '../../contexts/global.context';

// import LocationDisplay from './location-display/location-display.component';
import DataTypeAndDate from './data-type-and-date/data-type-and-date.component';
import Button from '../button/button.component';

import './options-panel.styles.scss';

export default function OptionsPanel() {
  const { pageInfo, tabsSharedState } = useContext(TabInfoContext);
  const { display, toggleDisplay, selectedTab, selectedSubTab, handleUpdate } = useContext(GlobalContext);
  // const { display, toggleDisplay, selectedTab, selectedSubTab, handleUpdate, selectedLocation } = useContext(GlobalContext);

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleHideShow = () => {
    setExpanded(false);
    toggleDisplay();
  };

  return (
    <div className='options-panel-container'>
      {/* <LocationDisplay address={selectedLocation?.address} /> */}
      <DataTypeAndDate />
      
      <div className='options-panel-selectors'>
        {selectedSubTab in pageInfo && pageInfo[selectedSubTab][display].options.map((info, i) => {
          if ('showIfSubTab' in info && !(info.showIfSubTab.includes(selectedSubTab))) {
            return '';
          }
          
          const useSharedState = Boolean(info.useSharedState);
          let value = useSharedState ? tabsSharedState[info.useSharedState].value : info.props.value;
          if (typeof value === 'function') {
            value = value({selectedTab, selectedSubTab});
          }

          let resetValue = info.props.resetValue;
          if (useSharedState && tabsSharedState[info.useSharedState].resetValue) {
            resetValue = typeof tabsSharedState[info.useSharedState].resetValue === 'function' ? tabsSharedState[info.useSharedState].resetValue({selectedTab, selectedSubTab})[0] : tabsSharedState[info.useSharedState].resetValue;
          }

          return React.createElement(
            info.component,
            {
              ...info.props,
              ...(useSharedState ? tabsSharedState[info.useSharedState] : {}),
              value,
              resetValue,
              id: info.id,
              key: i,
              expanded,
              handleChange,
              handleChangeSelection: (newValue) => {
                if (info.useSharedState) {
                  handleUpdate('tabSharedState', {
                    sharedStateNameToEdit: info.useSharedState,
                    newValue
                  });
                } else {
                  handleUpdate('tabOptions', {
                    tabToUpdate: selectedTab,
                    subTabToUpdate: selectedSubTab,
                    pageToUpdate: display,
                    selectorIndexToUpdate: i,
                    keyToUpdate: 'value',
                    persist: info.persist,
                    newValue
                  });
                }
              }
            });
          })
        }
      </div>
    
      <Button buttonType='centeredSmall' onClick={handleHideShow} style={{ margin: '12px auto' }}>
        {display === 'map' ? 'Show Charts' : 'Hide Charts'}
      </Button>
    </div>
  );
}
