import React, { useEffect, useState } from 'react';

import { TextField } from '@mui/material';

import './threshold-range-item.styles.scss';

// eslint-disable-next-line no-useless-escape
const decimalRegex = new RegExp('^[+-]?(([1-9][0-9]*)?[0-9](\.[0-9]*)?|\.[0-9]+)$');

export default function ThresholdRangeItem({ value, color, editThreshold, step, min, max }) {
  // This state along with the two useEffects handle debouncing the ThresholdItem when the user types/increments/decrements
  const [tValue, setTValue] = useState(value);

  useEffect(() => {
    setTValue(value);
  }, [value]);

  useEffect(() => {
      const setNewValue = setTimeout(() => {
        // Coerce to string
        const tValueStr = String(tValue);

        // Check if new value is NaN or an improper number
        if (Number.isNaN(tValueStr) || !decimalRegex.test(tValueStr)) {
          setTValue(value);
        } else if (tValue !== value) {
          // If the new value is different from the current one, parse it to float or integer
          const tValueNum = tValueStr.includes('.') ? parseFloat(tValueStr) : parseInt(tValueStr);

          // Clamp the new value to min and max, update the threshold
          if (tValueNum < min) {
            setTValue(min);
          } else if (tValueNum > max) {
            setTValue(max);
          } else {
            editThreshold(tValueNum);
          }
        }
      }, 1000);
      return () => clearTimeout(setNewValue)
  }, [tValue]);
  
  const handleIncrementThreshold = () => {
    const newValue = Math.round((value + step) * 10) / 10;
    if (newValue <= max) {
      editThreshold(newValue);
    }
  };

  const handleDecrementThreshold = () => {
    const newValue = Math.round((value - step) * 10) / 10;
    if (newValue >= min) {
      editThreshold(newValue);
    }
  };

  return (
    <React.Fragment>
      <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
        <TextField
          variant='standard'
          value={tValue}
          onChange={(e) => setTValue(e.target.value)}
          className='threshold-range-item-value'
          inputProps={{ style: { textAlign: 'center' } }}
        />
      </div>

      <div className='threshold-range-item-controls' style={{ color, userSelect: 'none' }}>
        <div className='arrow-container' onClick={handleDecrementThreshold}>
          <span className='arrow'>&#10094;</span>
        </div>
        <div className='arrow-container' onClick={handleIncrementThreshold}>
          <span className='arrow'>&#10095;</span>
        </div>
      </div>
    </React.Fragment>
  );
}