import { parseISO, isBefore, format, isWithinInterval } from 'date-fns';

import calcTimeseriesOptions from "../../utilities/charts/timeseries-options";
import calcOnTodayValueAmountOptions from "../../utilities/charts/onToday-value-amount-options";
import calcMaxValueDateOptions from "../../utilities/charts/max-value-date-options";
import calcThresholdsOptions from "../../utilities/charts/thresholds-options";
import updateOverlayFromR2 from '../../utilities/overlayUpdateFns';
import { MODELS } from '../../utilities/chill-models';
import { refreshChillChartData, parseTemperaturesToSplines } from '../../utilities/charts/chart-data-functions';

import { TODAY } from '../../contexts/constants';

const TAB_NAME = 'Chill Accumulation';

const addName = (chartData, address, fn) => {
  return fn(chartData, address, 'Chill Units');
};

const info = {
  name: TAB_NAME,
  subTabs: [
    {
      name: 'North Carolina Model'
    },{
      name: 'Utah Model'
    },{
      name: 'Dynamic Model'
    },
  ],
  chartInfo: {
    chartSelectorId: 'chillUnitsChartSelector',
    charts: [{ 
      name: 'Chill Unit Accumulation Timeseries',
      func: (chartData, address, ...rest) => addName(chartData, address, calcTimeseriesOptions)
    },{ 
      name: 'Chill Units Achieved On This Date',
      func: (chartData, address, ...rest) => addName(chartData, address, calcOnTodayValueAmountOptions)
    },{ 
      name: 'Date of Maximum Chill Units',
      func: (chartData, address, ...rest) => addName(chartData, address, calcMaxValueDateOptions)
    },{ 
      name: 'Date of Threshold Exceedance',
      func: (chartData, address, ...rest) => addName(chartData, address, calcThresholdsOptions)
    }],
  },
  onChange: [{
    whenXChanges: ['selectedSubTab'],
    handleChange: ({ selectedSubTab }, _, { updateOverlayData }) => {
      const jsonFileName = `${selectedSubTab.split(' ').join('_').toLowerCase()}.json`;
      const jsonFileDataKey = 'departures';
      return updateOverlayFromR2(jsonFileName, jsonFileDataKey)
        .then(d => () => updateOverlayData(d));
    },
    showLoading: true
  },{
    whenXChanges: ['chillUnitsThresholdSelector', 'chillUnitsSeasonBoundsSelector', 'rawChartData', 'selectedSubTab'],
    handleChange: ({ selectedTab, selectedSubTab, selectedLocation, tabsSharedState, rawChartData }, _, { setRawChartData, setChartData }) => {
      if (selectedTab === TAB_NAME && selectedLocation in rawChartData && 'mintMaxt' in rawChartData[selectedLocation]) {
        if (!(selectedTab in rawChartData[selectedLocation])) {
          const newRawChartData = { ...rawChartData };
          const monthlyObjArray = parseTemperaturesToSplines(newRawChartData[selectedLocation]['mintMaxt']);
          newRawChartData[selectedLocation][selectedTab] = monthlyObjArray;
          return () => setRawChartData(newRawChartData);
        } else {
          const newChartData = refreshChillChartData(rawChartData[selectedLocation][selectedTab], tabsSharedState.chillUnitsThresholdSelector.value, tabsSharedState.chillUnitsSeasonBoundsSelector.value, TODAY, MODELS[selectedSubTab]);
          return () => setChartData(newChartData);
        }
      }
      return () => {};
    },
    showLoading: true
  }],
  mapInfo: {
    overlayKeySelectorId: 'departureSelector',
    regionsSelectorId: 'regionSelector'
  },
  optionsPanel: {
    map: [{
      component: 'listSelector',
      id: 'chill-units-regions',
      useSharedState: 'regionSelector'
    },{
      component: 'departureSelector',
      id: 'chill-units-comparison',
      useSharedState: 'departureSelector'
    }],
    chart: [{
      component: 'listSelector',
      id: 'chill-units-charts-selector',
      useSharedState: 'chillUnitsChartSelector',
      props: {
        listTypeSingular: 'Chart',
        listTypePlural: 'Charts',
        showAll: false,
        showNone: false
      }
    },{
      component: 'thresholdSelector',
      id: 'chill-units-threshold-selector',
      useSharedState: 'chillUnitsThresholdSelector',
      props: {
        columnHeading: 'Chill Unit',
        minNum: 0,
        maxNum: 3,
        minValue: 0,
        maxValue: Infinity,
        step: 100,
        initValue: 1000,
        colors: ['#bae4b3', '#74c476', '#238b45']
      }
    },{
      component: 'seasonBoundsSelector',
      id: 'chill-units-season-bounds-selector',
      useSharedState: 'chillUnitsSeasonBoundsSelector'
    }]
  },
  componentFns: {
    title: ({display, selectedSubTab, overlayDataDate, chartData}) => {
      try {
        let sdate, edate;
        if (display === 'map') {
          const dateObj = parseISO(overlayDataDate);
          const isCurrentSeasonYear = isBefore(dateObj, new Date(dateObj.getFullYear(), 8, 1));
          const syear = dateObj.getFullYear() - (isCurrentSeasonYear ? 1 : 0);
          sdate = format(new Date(syear, 8, 1), 'LLL do, yyyy');
          edate = format(dateObj, 'LLL do, yyyy');
        } else {
          sdate = format(parseISO(chartData.timeseries.dates[0]), 'LLL do, yyyy');
          edate = format(parseISO(chartData.timeseries.dates.slice(-1)[0]), 'LLL do, yyyy');
        }
        
        return {
          type: `${selectedSubTab} Chill Units`,
          sdate,
          edate
        }
      } catch {
        return null;
      }
    }
  },
  textPanelInfo: [{
    name: 'How To Use Tool',
    markdownFilename: 'how-to-use_chill.md'
  },{
    name: 'FAQ',
    markdownFilename: 'faq_default.md'
  },{
    name: 'About The Data',
    markdownFilename: 'about-the-data_chill.md'
  }]
};

export default info;
export const chillUnitsSharedStateDefinitions = {
  'chillUnitsChartSelector': {
    options: info.chartInfo.charts,
    initValue: info.chartInfo.charts
  },
  'chillUnitsThresholdSelector': {
    initValue: []
  },
  'chillUnitsSeasonBoundsSelector': {
    persist: false,
    initValue: () => {
      const isCurrentSeasonYear = isWithinInterval(TODAY, {
        start: new Date(TODAY.getFullYear(), 0, 1),
        end: new Date(TODAY.getFullYear(), 7, 31)
      });
      const startYear = TODAY.getFullYear() - (isCurrentSeasonYear ? 1 : 0);
      
      return [`${startYear}-09-01`, format(TODAY, 'yyyy-MM-dd')];
    }
  }
}