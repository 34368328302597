import React, { useState, useContext, useEffect } from 'react';
import {
  Tabs,
  Tab,
  Box,
  useMediaQuery
} from '@mui/material';


import { TabInfoContext } from '../../contexts/tab-info.context';
import { GlobalContext } from '../../contexts/global.context';

import MarkdownToJsx from '../markdown-to-jsx/markdown-to-jsx.component';

import './text-panel.styles.scss';


function a11yProps(idx) {
  return {
    id: `vertical-tab-${idx}`,
    'aria-controls': `vertical-tabpanel-${idx}`,
  };
}

export default function TextPanel() {
  const tabInfoContextObj = useContext(TabInfoContext);
  const { textPanelInfo } = tabInfoContextObj;
  const globalContextObj = useContext(GlobalContext);
  const { display, selectedSubTab } = globalContextObj;

  const [tabIdx, setTabIdx] = useState(0);

  useEffect(() => {
    setTabIdx(0);
  }, [textPanelInfo, display]);

  const isSmallScreen = useMediaQuery('(max-width:700px)');

  const handleTabChange = (e, newTab) => {
    setTabIdx(newTab);
  };

  const mdTabs = textPanelInfo.filter(t => {
    let showTab = true;

    if (t.onlyShowIfChart && display !== 'chart') {
      showTab = false;
    }

    if (t.onlyShowIfMap && display !== 'map') {
      showTab = false;
    }

    if (Array.isArray(t.onlyShowIfSubTab) && !t.onlyShowIfSubTab.includes(selectedSubTab)) {
      showTab = false;
    }
    
    return showTab;
  });
  if (mdTabs.length === 0 || tabIdx >= mdTabs.length ) {
    return '';
  } else {
    return (
      <Box className='text-panel'>
        <Box
          sx={{ flexGrow: 1, display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row' }}
        >
          <Tabs
            orientation={isSmallScreen ? 'horizontal' : 'vertical'}
            variant={isSmallScreen ? 'scrollable' : 'standard'}
            scrollButtons={isSmallScreen}
            allowScrollButtonsMobile={isSmallScreen}
            value={tabIdx}
            onChange={handleTabChange}
            aria-label="Tabs controlling text content"
            sx={{ border: 1, borderColor: 'divider', minWidth: '180px', height: 'fit-content' }}
          >
            {mdTabs.map(({ name }, idx) => <Tab className='tabs' label={name} key={'tab-' + name} {...a11yProps(idx)} sx={{ padding: '12px 8px' }} />)}
          </Tabs>
  
          <Box
            role="tabpanel"
            id={`vertical-tabpanel-${tabIdx}`}
            aria-labelledby={`vertical-tab-${tabIdx}`}
            className='text-panel-md-content'
          >
            {'markdownFilename' in mdTabs[tabIdx] ?
              <MarkdownToJsx
                path={`${process.env.PUBLIC_URL}/markdown/${mdTabs[tabIdx].markdownFilename}`}
              />
              :
              <MarkdownToJsx
                markdownText={mdTabs[tabIdx].generateMarkdown({ ...tabInfoContextObj, ...globalContextObj })}
              />
            }
          </Box>
        </Box>
      </Box>
    );
  }
}