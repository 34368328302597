import { calculateSeasonYear, isInSeason } from "./seasonCalculations";

export function averageArray(arr) {
  return arr.reduce((acc, v, i) => {
    acc += v;
    if (i === arr.length - 1) {
      return Math.round(acc / arr.length * 100) / 100;
    } else {
      return acc;
    }
  }, 0)
}

export function countAbove(arr, threshold) {
  return arr.reduce((acc, v) => {
    if (v >= threshold) {
      acc += 1;
    }
    return acc;
  }, 0);
}

export const isPastThreshold = (threshold, direction) => {
  if (direction === 'above') {
    return (v) => v > threshold;
  } else if (direction === 'below') {
    return (v) => v < threshold;
  } else if (direction === 'range') {
    return (v) => threshold[0] <= v && v <= threshold[1];
  } else {
    return (v) => v === threshold;
  }
};

export const refreshThresholdChartData = (temps, dataIdx, thresholds, seasonBounds, direction, TODAY) => {
  const compFn = isPastThreshold(thresholds, direction);
  const newChartData = {
    timeseries: {
      dates: [],
      current: []
    },
    yearlySeasons: {
      years: [],
      toDate: [],
      fullSeason: []
    }
  };
  
  const seasonStartDay = seasonBounds[0].slice(5,10);
  const seasonEndDay = seasonBounds[1].slice(5,10);
  const today = isInSeason(TODAY.toISOString(), seasonBounds[0], seasonBounds[1]) ? TODAY.toISOString().slice(0,10) : seasonBounds[1];
  newChartData.yearlySeasons.toDateDate = today.slice(0,10);
  
  let idxYearStart = null;
  let lastIdxProcessed = 0;
  for (let i = 0; i < temps.length; i++) {
    const strDate = temps[i][0];
    const isToday = strDate.slice(5,10) === today.slice(5,10);

    if (isToday && idxYearStart !== null) {
      const seasonData = temps.slice(idxYearStart, i + 1);
      newChartData.yearlySeasons.toDate.push(seasonData.filter((dayData) => compFn(dayData[dataIdx])).length);
    }
    
    if (strDate.slice(5) === seasonEndDay && idxYearStart !== null) {
      const seasonData = temps.slice(idxYearStart, i + 1);
      newChartData.yearlySeasons.years.push(parseInt(strDate.slice(0,4)));
      newChartData.yearlySeasons.fullSeason.push(seasonData.filter((dayData) => compFn(dayData[dataIdx])).length);
      lastIdxProcessed = i;
    }
    
    if (strDate.slice(5) === seasonStartDay) {
      idxYearStart = i;
    }
  }

  if (idxYearStart !== null && (lastIdxProcessed === null || idxYearStart > lastIdxProcessed)) {
    const currentSeasonData = temps.slice(idxYearStart);
    const seasonYear = calculateSeasonYear(currentSeasonData[currentSeasonData.length - 1][0], seasonBounds[0], seasonBounds[1]);

    newChartData.yearlySeasons.years.push(seasonYear);
    newChartData.yearlySeasons.toDate.push(currentSeasonData.filter((dayData) => compFn(dayData[dataIdx])).length);
    newChartData.yearlySeasons.fullSeason.push(null);
    newChartData.timeseries = currentSeasonData.reduce((acc, dayData) => {
      acc.dates.push(dayData[0]);
      acc.current.push(dayData[dataIdx]);
      return acc;
    }, { dates: [], current: [] });
  } else if (idxYearStart !== null && lastIdxProcessed !== null && idxYearStart < lastIdxProcessed) {
    const currentSeasonData = temps.slice(idxYearStart, lastIdxProcessed + 1);
    newChartData.timeseries = currentSeasonData.reduce((acc, dayData) => {
      acc.dates.push(dayData[0]);
      acc.current.push(dayData[dataIdx]);
      return acc;
    }, { dates: [], current: [] });
  }

  return { newChartData, newCurrentSeasonChartData: null };
};

export const refreshRangeChartData = (temps, thresholds, seasonBounds, TODAY) => {
  // Count of days with max temp in threshold range
  function isInRange(val) {
    return thresholds[0] <= val && val <= thresholds[1];
  }

  const newChartData = {
    thresholds,
    timeseries: {
      dates: [],
      current: []
    },
    yearlySeasons: {
      years: [],
      toDate: [],
      fullSeason: []
    }
  };
  
  const seasonStartDay = seasonBounds[0].slice(5,10);
  const seasonEndDay = seasonBounds[1].slice(5,10);
  const today = isInSeason(TODAY.toISOString(), seasonBounds[0], seasonBounds[1]) ? TODAY.toISOString().slice(0,10) : seasonBounds[1];
  newChartData.yearlySeasons.toDateDate = today.slice(0,10);
  
  let idxYearStart = null;
  let lastIdxProcessed = 0;
  for (let i = 0; i < temps.length; i++) {
    const strDate = temps[i][0];
    const isToday = strDate.slice(5,10) === today.slice(5,10);

    if (isToday && idxYearStart !== null) {
      const seasonData = temps.slice(idxYearStart, i + 1);

      newChartData.yearlySeasons.toDate.push(seasonData.filter((dayData) => isInRange(dayData[2])).length);
    }
    
    if (strDate.slice(5) === seasonEndDay && idxYearStart !== null) {
      const seasonData = temps.slice(idxYearStart, i + 1);
      newChartData.yearlySeasons.years.push(parseInt(strDate.slice(0,4)));
      
      // Count of days with max temp in threshold range
      newChartData.yearlySeasons.fullSeason.push(seasonData.filter((dayData) => isInRange(dayData[2])).length);
      lastIdxProcessed = i;
    }
    
    if (strDate.slice(5) === seasonStartDay) {
      idxYearStart = i;
    }
  }

  if (idxYearStart !== null && (lastIdxProcessed === null || idxYearStart > lastIdxProcessed)) {
    const currentSeasonData = temps.slice(idxYearStart);
    const seasonYear = calculateSeasonYear(currentSeasonData[currentSeasonData.length - 1][0], seasonBounds[0], seasonBounds[1]);

    newChartData.yearlySeasons.years.push(seasonYear);
    newChartData.yearlySeasons.toDate.push(currentSeasonData.filter((dayData) => isInRange(dayData[2])).length);
    newChartData.yearlySeasons.fullSeason.push(null);
    newChartData.timeseries = currentSeasonData.reduce((acc, dayData) => {
      acc.dates.push(dayData[0]);
      acc.current.push(dayData[2]);
      return acc;
    }, { dates: [], current: [] });
  } else if (idxYearStart !== null && lastIdxProcessed !== null && idxYearStart < lastIdxProcessed) {
    const currentSeasonData = temps.slice(idxYearStart, lastIdxProcessed + 1);
    newChartData.timeseries = currentSeasonData.reduce((acc, dayData) => {
      acc.dates.push(dayData[0]);
      acc.current.push(dayData[2]);
      return acc;
    }, { dates: [], current: [] });
  }

  return { newChartData, newCurrentSeasonChartData: null };
};