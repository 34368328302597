import { renderToStaticMarkup } from 'react-dom/server';

import TooltipSeason from '../../components/tooltip-season/tooltip-season.component';

const BLUE_COLOR = '#6da9f7';
const RED_COLOR = '#d45959';
const GREY_COLOR = '#adadad';

export function calcCaseStudy1TimeseriesOptions(chartData, address) {
  const min = Math.min(...chartData.timeseries.current, chartData.thresholds[0] - 5);
  const max = Math.max(...chartData.timeseries.current, chartData.thresholds[1] + 5);
  
  return {
    chart: {
      marginRight: 20
    },
    title: {
      text: 'Timeseries Option 1: Highlighted Range',
    },
    subtitle: {
      text: `Location: ${address}`,
    },
    series: [
      {
        data: chartData.timeseries.current,
        name: 'Current Season',
        zones: [{
          value: chartData.thresholds[0],
          color: BLUE_COLOR
        },{
          value: chartData.thresholds[1],
          color: 'rgb(100,100,100)'
        },{
          color: RED_COLOR
        }]
      }
    ],
    xAxis: {
      categories: chartData.timeseries.dates.map(d => d.slice(5,10).replace('-', '/')),
      title: {
        text: 'Day of Year',
      },
    },
    yAxis: [
      {
        min,
        max,
        title: {
          text: 'Temperature (°F)',
        },
        labels: {
          style: {
            fontFamily:
              'Roboto, "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif',
          },
        },
        plotBands: [{
          from: chartData.thresholds[0],
          to: chartData.thresholds[1]
        }]
      },
    ],
    legend: {
      enabled: false,
    },
  };
}

export function calcCaseStudy1AnnualOptions(chartData, address, seasonBounds) {
  const { years, toDate, toDateDate, fullSeason } = chartData.yearlySeasons;
  const min = 0;
  const max = Math.max(...fullSeason, ...toDate);

  if (typeof seasonBounds === 'function') {
    seasonBounds = seasonBounds();
  }

  const seasonSDate = seasonBounds[0].slice(5,10).replace("-","/");
  const seasonEDate = seasonBounds[1].slice(5,10).replace("-","/");

  const finalCurrentYearDate = chartData.timeseries.dates.slice(-1)[0];
  let finalDataDate = new Date(finalCurrentYearDate) < new Date(toDateDate) ? finalCurrentYearDate : toDateDate;
  finalDataDate = finalDataDate.slice(5,10).replace('-','/')

  return {
    chart: {
      marginRight: 20,
      type: 'column'
    },
    plotOptions: {
      column: {
        grouping: false
      }
    },
    title: {
      text: `Occurrences in Range for the Season: ${seasonSDate} to ${seasonEDate}`,
    },
    subtitle: {
      text: `Location: ${address}`,
    },
    series: [
      {
        data: fullSeason,
        name: 'At End of Season',
        color: GREY_COLOR,
        opacity: 0.6,
        borderColor: GREY_COLOR,
        groupPadding: 0
      },{
        data: toDate,
        name: 'To Date',
        color: BLUE_COLOR,
        pointPadding: 0.125
      }
    ],
    xAxis: {
      categories: years,
      crosshair: {
        color: 'rgb(220,220,220)',
        width: 0.5,
      },
      title: {
        text: 'Year',
      },
    },
    yAxis: [
      {
        min,
        max,
        startOnTick: false,
        endOnTick: false,
        tickAmount: 5,
        gridLineWidth: 0,
        title: {
          text: 'Number of Occurrences',
        },
        labels: {
          style: {
            fontFamily:
              'Roboto, "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif',
          },
        },
        plotLines: [
          {
            color: 'rgb(150,150,150)',
            value: Math.round(toDate.slice(0, -1).reduce((a,b) => a + b) / (toDate.length - 1)),
            label: {
              text: 'To Date Normal',
              style: {
                color: 'rgb(80,80,80)',
              },
            },
            zIndex: 4
          },
        ],
      },
    ],
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      outside: true,
      useHTML: true,
      backgroundColor: 'white',
      formatter: function () {
        if (!this || !this.points) return '';

        const date = this.points[0].x;
        const isCurrentYear = date === years.slice(-1)[0];

        return renderToStaticMarkup(
          <div className='max-chill-tooltip-content-container'>
            <TooltipSeason year={date} color={BLUE_COLOR} />

            {this.points.reverse().map(point => (
              <div key={point.y} className='mc-container' style={{ justifyContent: 'space-between' }}>
                <div className='mc-units'>{point.series.name === 'To Date' ? `To ${isCurrentYear ? finalDataDate : toDateDate.slice(5,10).replace('-','/')}` : point.series.name}: </div>
                <div className='mc-number'>{point.y}</div>
              </div>
            ))}
          </div>
        );
      },
    },
  };
}