import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import { TabInfoProvider } from './contexts/tab-info.context';
import { GlobalProvider } from './contexts/global.context';

import './index.scss';

// Polyfill to allow react-markdown to work on Safari 15
if (!Object.hasOwn) {
  Object.hasOwn = (obj, prop) =>
    Object.prototype.hasOwnProperty.call(obj, prop);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <TabInfoProvider>
      <GlobalProvider>
        <App />
      </GlobalProvider>
    </TabInfoProvider>
  </React.StrictMode>
);
