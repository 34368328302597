import {
  parseISO,
  differenceInDays,
  isLeapYear,
  isBefore,
  addDays,
  format
} from 'date-fns';
import Spline from 'cubic-spline';

import { DynamicChillUnitsModel } from '../chill-models.js';
import roundXDigits from '../round.jsx';
import { calculateSeasonYear, isInSeason } from '../seasonCalculations.js';

// Creates spline function for temperature interpolation
const calcSpline = (ys) => {
  // Duplicate first and last day of temperature observations. This serves to mitigate the tails that the spline generates at the start and end
  ys.unshift(ys[0], ys[1]);
  ys.push(...ys.slice(-2));

  // Create xs array that represents the hour of each temperature observation
  const xs = Array.from({ length: ys.length }, (v, i) => 12 * i);
  
  // Create Spline from ACIS data
  return new Spline(xs, ys);
};

const parseTemperaturesToSplines = (temperatures) => {
  const monthly = [];
  let currMonthTemperatures = {
    year: null,
    month: null,
    dates: [],
    temperatures: []
  };

  // Loop days adding to current month object until the first of next month is reached, then add the month data to array and reset for new month
  // mint and maxt are added to same array to be used as 'ys' in spline calculations
  for (let i = 0; i < temperatures.length; i++) {
    const [ dateStr, mint, maxt ] = temperatures[i];

    const year = dateStr.slice(0,4);
    const month = dateStr.slice(5,7);
    const day = dateStr.slice(8,10);

    if (day === '01') {
      if (currMonthTemperatures.year) {
        currMonthTemperatures.spline = calcSpline(currMonthTemperatures.temperatures);
        monthly.push({ ...currMonthTemperatures });
      }

      currMonthTemperatures = {
        year: parseInt(year),
        month: parseInt(month),
        dates: [],
        temperatures: []
      };
    }

    currMonthTemperatures.dates.push(dateStr);
    currMonthTemperatures.temperatures.push(mint);
    currMonthTemperatures.temperatures.push(maxt);
  }

  // Adds ending partial month
  currMonthTemperatures.spline = calcSpline(currMonthTemperatures.temperatures);
  monthly.push({ ...currMonthTemperatures });
  
  return monthly;
};



const calcRunningAverage = (subsetSize, arr) => {
  const runningAverage = [];
  for (let i = subsetSize; i <= arr.length; i++) {
    let sum = 0;
    let x = i - subsetSize;
    while (x < i) {
      sum += arr[x];
      x++;
    }
    runningAverage.push(roundXDigits(sum / subsetSize, 1));
  }
  return runningAverage;
};

const calcSeasonDates = (dateOccurred, seasonStartDay) => {
  // If date occurred is on Feb. 29th, change to Mar. 1st to avoid error
  if (dateOccurred.slice(5) === '02-29') {
    dateOccurred = dateOccurred.slice(0,5) + '03-01';
  }

  // Handle adjusting for leap years
  let doObj = parseISO(dateOccurred);
  if (isLeapYear(doObj) && !isBefore(doObj, new Date(doObj.getFullYear(), 2, 1))) {
    doObj = addDays(doObj, 1);
  }

  // Coerce to predefined year in order to chart day of season instead of actual date
  const dayOfSeason = Date.parse(format(doObj, '2021-MM-dd'));
  const dosDate = new Date(dayOfSeason);
  const seasonStartYear = isBefore(dosDate, parseISO('2021-' + seasonStartDay)) ? '2020' : '2021';
  
  const daysIntoSeason = differenceInDays(
    dosDate,
    parseISO(seasonStartYear + seasonStartDay)
  );

  return { dayOfSeason, daysIntoSeason };
};

const calcChillChartData = (chillModel) => {
  return function(monthlyObjArray, thresholds, seasonBounds, TODAY) {
    const today = isInSeason(TODAY.toISOString(), seasonBounds[0], seasonBounds[1]) ? TODAY.toISOString().slice(0,10) : seasonBounds[1];

    const sortedThresholds = thresholds.sort((a, b) => b - a);
    const newChartData = {
      years: [],
      maxs: {
        dateOccurred: [],
        values: [],
        dayOfSeason: [],
        daysIntoSeason: [],
      },
      onToday: {
        dateOccurred: seasonBounds[1],
        values: [],
        ...calcSeasonDates(today, seasonBounds[0].slice(5,10))
      },
      thresholds: Array.from({ length: sortedThresholds.length }, (v, i) => ({
        threshold: sortedThresholds[i],
        dateOccurred: [],
        dayOfSeason: [],
        daysIntoSeason: [],
      })),
    };

    const seasonStartDay = seasonBounds[0].slice(5,10);
    const seasonEndDay = seasonBounds[1].slice(5,10);

    let idxYearStart = null;
    let lastIdxProcessed = -1;
    let lastCompleteSeason = null;
    let dateIdx = 0;

    let dynamicChillUnitsModel, dailyChill, yearDates, chillSum, chillMax, thresholdsToPass;
    monthlyObjArray.forEach(({ dates, spline }) => {
      for (let i = 0; i < dates.length; i++) {
        const strDate = dates[i];
        const isToday = strDate.slice(5,10) === today.slice(5,10);

        
        // If current day is the start of the season
        if (strDate.slice(5) === seasonStartDay) {
          // Move pointer
          idxYearStart = dateIdx;

          // Reset all variables used for tracking season
          thresholdsToPass = [...sortedThresholds];
          dailyChill = [];
          yearDates = [];
          chillMax = ['', 0];
          chillSum = 0;

          // Create a new Dynamic model for each season (only gets used if Dynamic model is selected)
          dynamicChillUnitsModel = new DynamicChillUnitsModel();
        }

        if (idxYearStart !== null && idxYearStart > lastIdxProcessed) {
          // Start hour is adjusted by one day to account for the duplicated first day (see calcSpline function)
          const startHour = (i + 1) * 24;
          const endHour = startHour + 24;
          
          for (let j = startHour; j < endHour; j++) {
            // Use Spline to calculate the hourly temp
            const hourlyTemp = spline.at(j);
      
            // Use hourly temp to calculate hourly chill units
            const chill = chillModel(hourlyTemp, dynamicChillUnitsModel);
            // Add chill units to sum, but ensure that the sum never goes below 0
            chillSum = Math.max(0, chillSum + chill);
      
            // Check if new sum is new max
            if (chillSum > chillMax[1]) {
              chillMax = [strDate, chillSum];
            }
            
            // Check if chill unit sum has crossed any of the thresholds provided in thresholdArr
            for (let k = 0; k < thresholdsToPass.length; k++) {
              const threshold = thresholdsToPass[k];
              
              // Store the date that the threshold was crossed
              if (chillSum >= threshold) {
                thresholdsToPass[k] = Infinity;
                const { dayOfSeason, daysIntoSeason } = calcSeasonDates(strDate, seasonBounds[0].slice(5,10));
                newChartData.thresholds[k].dateOccurred.push(strDate);
                newChartData.thresholds[k].dayOfSeason.push(dayOfSeason);
                newChartData.thresholds[k].daysIntoSeason.push(daysIntoSeason);
              }
            }
          }

          // Add day to tracking arrays
          dailyChill.push(chillSum);
          yearDates.push(strDate);

          // If it is today, then set onToday value
          if (isToday) {
            newChartData.onToday.values.push(chillSum);
          }

          // If it is the end of the season (and there was a season being tracked)
          if (strDate.slice(5) === seasonEndDay) {
            // Add season year to years array. Season year could be different from strDate's year
            const seasonYear = calculateSeasonYear(strDate, seasonBounds[0], seasonBounds[1]);
            newChartData.years.push(seasonYear);

            if (chillMax[0] === '') {
              chillMax[0] = strDate;
            }

            // Add season data to relevant arrays
            const { dayOfSeason, daysIntoSeason } = calcSeasonDates(chillMax[0], seasonStartDay);
            newChartData.maxs.values.push(chillMax[1]);
            newChartData.maxs.dateOccurred.push(chillMax[0]);
            newChartData.maxs.dayOfSeason.push(dayOfSeason);
            newChartData.maxs.daysIntoSeason.push(daysIntoSeason);
            
            // Update to the index of the current date, as it was the last to be used
            lastIdxProcessed = dateIdx;

            // Update last complete season data for use below
            lastCompleteSeason = { current: [...dailyChill], dates: [...yearDates] };
          }
        }

        dateIdx++;
      }
    });
      
    if (idxYearStart !== null && idxYearStart > lastIdxProcessed) {
      // Data ended in middle of season, treat it like the end of the season
      // Add season year to years array. Season year could be different from strDate's year
      const seasonYear = calculateSeasonYear(yearDates.slice(-1)[0], seasonBounds[0], seasonBounds[1]);
      newChartData.years.push(seasonYear);

      if (chillMax[0] === '') {
        chillMax[0] = yearDates.slice(-1)[0];
      }

      // Add season data to relevant arrays
      const { dayOfSeason, daysIntoSeason } = calcSeasonDates(chillMax[0], seasonStartDay);
      newChartData.maxs.values.push(chillMax[1]);
      newChartData.maxs.dateOccurred.push(chillMax[0]);
      newChartData.maxs.dayOfSeason.push(dayOfSeason);
      newChartData.maxs.daysIntoSeason.push(daysIntoSeason);

      // And use this partial season as the timeseries
      newChartData.timeseries = { current: dailyChill, dates: yearDates };
    } else if (idxYearStart !== null && lastIdxProcessed !== null && idxYearStart < lastIdxProcessed) {
      // Data ended between seasons, don't use the current data
      // Use the last complete season for the timeseries
      newChartData.timeseries = lastCompleteSeason;
    }
    
    return newChartData;
  }
};

const calcGddChartData = (gdds, thresholds, seasonBounds, TODAY) => {
  const seasonStartDay = seasonBounds[0].slice(5,10);
  const seasonEndDay = seasonBounds[1].slice(5,10);
  const today = isInSeason(TODAY.toISOString(), seasonBounds[0], seasonBounds[1]) ? TODAY.toISOString().slice(0,10) : seasonBounds[1];
  
  const sortedThresholds = [...thresholds].sort((a, b) => a - b);
  const newChartData = {
    years: [],
    onToday: {
      dateOccurred: null,
      values: [],
      dayOfSeason: null,
      daysIntoSeason: null
    },
    thresholds: Array.from({ length: sortedThresholds.length }, (v, i) => ({
      threshold: sortedThresholds[i],
      dateOccurred: [],
      dayOfSeason: [],
      daysIntoSeason: [],
    })),
    timeseries: {
      dates: [],
      current: [],
      last: [],
      normal: []
    }
  };
  const normalSums = {};

  let inTimeseries = false;
  let ts = [...sortedThresholds];
  let currSum = 0;
  let idxYearStart = null;
  let lastIdxProcessed = 0;
  for (let i = 0; i < gdds.length; i++) {
    const [strDate, dayGddAmount] = gdds[i];

    if (strDate.slice(5) === seasonStartDay) {
      currSum = 0;
      inTimeseries = true;
      ts = [...sortedThresholds];
      idxYearStart = i;
    }

    if (inTimeseries) {
      currSum += dayGddAmount;
      if (!(strDate.slice(5) in normalSums)) normalSums[strDate.slice(5)] = { sum: 0, count: 0 };
      normalSums[strDate.slice(5)].sum += currSum;
      normalSums[strDate.slice(5)].count += 1;
      
      if (ts.length && ts[0] <= currSum) {
        const { daysIntoSeason: currDaysIntoSeason, dayOfSeason: currDayOfSeason } = calcSeasonDates(strDate, seasonStartDay);
        const t = ts[0];
        const tObj = newChartData.thresholds.find(obj => obj.threshold === t);
        tObj.dateOccurred.push(strDate);
        tObj.dayOfSeason.push(currDayOfSeason);
        tObj.daysIntoSeason.push(currDaysIntoSeason);
        ts.shift();
      }

      if (strDate.slice(5,10) === today.slice(5,10)) {
        let { daysIntoSeason: d1, dayOfSeason: d2 } = calcSeasonDates(strDate, seasonStartDay);
        newChartData.onToday.daysIntoSeason = d1;
        newChartData.onToday.dayOfSeason = d2;
        newChartData.onToday.dateOccurred = strDate;
        newChartData.onToday.values.push(currSum);
      }
    }
    
    if ((strDate.slice(5) === seasonEndDay && idxYearStart !== null) || (inTimeseries && i === gdds.length - 1)) {
      const seasonYear = calculateSeasonYear(strDate, seasonBounds[0], seasonBounds[1]);
      newChartData.years.push(seasonYear);
      
      ts.forEach(t => {
        const tObj = newChartData.thresholds.find(obj => obj.threshold === t);
        tObj.dateOccurred.push(null);
        tObj.dayOfSeason.push(null);
        tObj.daysIntoSeason.push(null);
      });
      
      lastIdxProcessed = i;
      inTimeseries = false;
    }
  }

  let currentSeasonData;
  if (idxYearStart !== null && (lastIdxProcessed === null || idxYearStart > lastIdxProcessed)) {
    currentSeasonData = gdds.slice(idxYearStart);
  } else if (idxYearStart !== null && lastIdxProcessed !== null && idxYearStart < lastIdxProcessed) {
    currentSeasonData = gdds.slice(idxYearStart, lastIdxProcessed + 1);
  }
  
  let currSeasonSum = 0;
  currentSeasonData.forEach(([date, gdd]) => {
    const monthDay = date.slice(5);
    if (monthDay !== '02-29') {
      currSeasonSum += gdd;
      newChartData.timeseries.current.push(roundXDigits(currSeasonSum,0));
      newChartData.timeseries.dates.push(date);
    }
  });

  return newChartData;
};

function calcChartData(values, thresholds, chartDataFunc, seasonBounds, today) {
  if (values.length) {
    const newChartData = chartDataFunc(
      [...values],
      [...thresholds], 
      typeof seasonBounds === 'function' ? seasonBounds() : seasonBounds,
      today
    );

    if ('maxs' in newChartData) {
      newChartData.maxs.subsetSize = 10;
      newChartData.maxs.valuesRunningAverage = calcRunningAverage(
        newChartData.maxs.subsetSize,
        newChartData.maxs.values
      );
      newChartData.maxs.dateOccurredRunningAverage = calcRunningAverage(
        newChartData.maxs.subsetSize,
        newChartData.maxs.dayOfSeason
      );
  
      newChartData.maxs.avgDaysIntoSeason = Math.round(newChartData.maxs.daysIntoSeason.reduce((a,b) => a + b, 0) / newChartData.maxs.daysIntoSeason.length);
    }

    newChartData.onToday.subsetSize = 10;
    newChartData.onToday.valuesRunningAverage = calcRunningAverage(
      newChartData.onToday.subsetSize,
      newChartData.onToday.values
    );

    newChartData.thresholds = newChartData.thresholds.map((tObj) => {
      tObj.avgDaysIntoSeason = Math.round(tObj.daysIntoSeason.reduce((a,b) => a + b, 0) / tObj.daysIntoSeason.length);
      return tObj;
    });

    return newChartData;
  }

  return null;
}

function refreshChillChartData(monthlyObjArray, thresholds, seasonBounds, today, chillModel) {
  return calcChartData(monthlyObjArray, thresholds, calcChillChartData(chillModel), seasonBounds, today);
}

function refreshGddChartData(gdds, thresholds, seasonBounds, today) {
  return calcChartData(gdds, thresholds, calcGddChartData, seasonBounds, today);
}

export { refreshChillChartData, refreshGddChartData, parseTemperaturesToSplines };