import { parseISO, format } from 'date-fns';

import { refreshThresholdChartData, refreshRangeChartData, countAbove, averageArray } from '../../utilities/extremes-utilities';
import { calcExtremesTimeseriesOptions, calcExtremesAnnualOptions } from '../../utilities/charts/extremes-chart-options';
import { calcCaseStudy1TimeseriesOptions, calcCaseStudy1AnnualOptions } from '../../utilities/charts/case-study-1';
import { calcCottonQualityTimeseriesOptions, calcCottonQualityAnnual1Options, calcCottonQualityTimeseries2Options, refreshCottonQualityChartData } from '../../utilities/charts/cotton-quality';
import updateOverlayFromR2 from '../../utilities/overlayUpdateFns';
import { TODAY } from '../../contexts/constants';

const TAB_NAME = 'Extreme Heat';
const DATA_NAME = 'mintMaxt';

const info = {
  name: TAB_NAME,
  subTabs: {
    'no-subheader-extreme-heat-group': [{
        name: 'Max > 90°F'
      },{
        name: 'Min > 70°F'
    }],
    'Case Studies': [{
      name: 'Case Study 1'
    },{
      name: 'Cotton Quality'
    }]
  },
  chartInfo: {
    chartSelectorId: 'extremeHeatChartSelector',
    charts: [{
      name: 'Hot Temperature Exceedance',
      showIfSubTab: ['Max > 90°F', 'Min > 70°F'],
      func: (chartData, address, tabInfoContextState, selectedSubTab) => {
        const thresholdValue = tabInfoContextState.tabsSharedState.extremeHeatThresholdSelector.value;
        let threshold = typeof thresholdValue === 'function' ? thresholdValue({ selectedSubTab })[0] : thresholdValue[0];
        return calcExtremesTimeseriesOptions(chartData, address, threshold, 'above', selectedSubTab.slice(0,3));
      }
    },{
      name: 'Occurrences in Season',
      showIfSubTab: ['Max > 90°F', 'Min > 70°F'],
      func: (chartData, address, tabInfoContextState, ...rest) => calcExtremesAnnualOptions(chartData, address, tabInfoContextState.tabsSharedState.extremeHeatSeasonBoundsSelector.value)
    },{
      name: 'CS1 Timeseries',
      showIfSubTab: ['Case Study 1'],
      func: (chartData, address, tabInfoContextState, ...rest) => calcCaseStudy1TimeseriesOptions(chartData, address, tabInfoContextState.tabsSharedState.extremeHeatSeasonBoundsSelector.value)
    },{
      name: 'CS1 Annual',
      showIfSubTab: ['Case Study 1'],
      func: (chartData, address, tabInfoContextState, ...rest) => calcCaseStudy1AnnualOptions(chartData, address, tabInfoContextState.tabsSharedState.extremeHeatSeasonBoundsSelector.value)
    },{
      name: 'Daily Average Temperatures',
      showIfSubTab: ['Cotton Quality'],
      func: (chartData, address, tabInfoContextState, ...rest) => calcCottonQualityTimeseriesOptions(chartData, address, tabInfoContextState.tabsSharedState.extremeHeatSeasonBoundsSelector.value)
    },{
      name: 'Occurrences Exceeding Extreme Temperature Threshold',
      showIfSubTab: ['Cotton Quality'],
      func: (chartData, address, tabInfoContextState, ...rest) => calcCottonQualityAnnual1Options(chartData, address, tabInfoContextState.tabsSharedState.extremeHeatSeasonBoundsSelector.value)
    },{
      name: 'Average Temperature from First Flower to First Boll',
      showIfSubTab: ['Cotton Quality'],
      func: (chartData, address, tabInfoContextState, ...rest) => calcCottonQualityTimeseries2Options(chartData, address, tabInfoContextState.tabsSharedState.extremeHeatSeasonBoundsSelector.value)
    }],
  },
  onChange: [{
    whenXChanges: ['selectedSubTab'],
    handleChange: ({ selectedSubTab }, _, { updateOverlayData }) => {
      let jsonFileName;
      if (selectedSubTab === 'Case Study 1') {
        jsonFileName = 'case_study_1.json';
      } else if (selectedSubTab === 'Cotton Quality') {
        jsonFileName = 'cotton_quality.json';
      } else {
        jsonFileName = `days_over_${selectedSubTab.slice(6,8)}_${selectedSubTab.slice(0,3).toLowerCase()}.json`;
      }
      const jsonFileDataKey = 'departures';
      return updateOverlayFromR2(jsonFileName, jsonFileDataKey, undefined, true)
        .then(d => () => updateOverlayData(d));
    },
    showLoading: true
  },{
    whenXChanges: ['extremeHeatThresholdSelector', 'extremeHeatSeasonBoundsSelector', 'cs3-season-bounds-selector', 'case-study-1-threshold-selector', 'cotton-quality-optimal-range-selector', 'cotton-quality-extreme-temperature-selector', 'cotton-quality-gdd-range-selector', 'rawChartData', 'selectedSubTab'],
    handleChange: ({ selectedTab, selectedSubTab, selectedLocation, tabInformation, tabsSharedState, rawChartData }, _, { setChartData }) => {
      if (
        selectedTab === TAB_NAME &&
        selectedLocation in rawChartData && DATA_NAME in rawChartData[selectedLocation]
      ) {
        let seasonBounds = tabsSharedState.extremeHeatSeasonBoundsSelector.value;
        if (typeof seasonBounds === 'function') seasonBounds = seasonBounds();
        
        let newChartData;
        if (selectedSubTab === 'Case Study 1') {
          const tab = tabInformation.find(({ name }) => name === TAB_NAME);
          const subTabChartOptions = tab.pageInfo[selectedSubTab].chart.options;
          const thresholds = subTabChartOptions.find(({ id }) => id === 'case-study-1-threshold-selector').props.value;
          ({ newChartData } = refreshRangeChartData(rawChartData[selectedLocation][DATA_NAME], thresholds, seasonBounds, TODAY));
        } else if (selectedSubTab === 'Cotton Quality') {
          const tab = tabInformation.find(({ name }) => name === TAB_NAME);
          const subTabChartOptions = tab.pageInfo[selectedSubTab].chart.options;
          seasonBounds = subTabChartOptions.find(({ id }) => id === 'cs3-season-bounds-selector').props.value;
          const gddThresholds = subTabChartOptions.find(({ id }) => id === 'cotton-quality-gdd-range-selector').props.value;
          const optimalThresholds = subTabChartOptions.find(({ id }) => id === 'cotton-quality-optimal-range-selector').props.value;
          const extremeThreshold = subTabChartOptions.find(({ id }) => id === 'cotton-quality-extreme-temperature-selector').props.value;
          ({ newChartData } = refreshCottonQualityChartData(rawChartData[selectedLocation].mintMaxt, rawChartData[selectedLocation].gddBase60, gddThresholds, optimalThresholds, extremeThreshold, seasonBounds, TODAY));
        } else {
          let thresholds = tabsSharedState.extremeHeatThresholdSelector.value;
          if (typeof thresholds === 'function') thresholds = thresholds({selectedSubTab})[0];
          const dataIdx = selectedSubTab.includes('Min') ? 1 : 2;
          ({ newChartData } = refreshThresholdChartData(rawChartData[selectedLocation][DATA_NAME], dataIdx, thresholds, seasonBounds, 'above', TODAY));
        }

        return () => setChartData(newChartData);
      }
      return () => {};
    },
    showLoading: true
  }],
  mapInfo: {
    overlayKeySelectorId: 'departureSelector',
    regionsSelectorId: 'regionSelector'
  },
  optionsPanel: {
    map: [{
      component: 'listSelector',
      id: 'extreme-heat-regions',
      useSharedState: 'regionSelector'
    },{
      component: 'departureSelector',
      id: 'extreme-heat-comparison',
      useSharedState: 'departureSelector'
    }],
    chart: [{
      component: 'listSelector',
      id: 'extreme-heat-charts',
      useSharedState: 'extremeHeatChartSelector',
      props: {
        listTypeSingular: 'Chart',
        listTypePlural: 'Charts',
        showAll: false,
        showNone: false
      }
    },{
      component: 'thresholdSelector',
      id: 'extreme-heat-threshold-selector',
      useSharedState: 'extremeHeatThresholdSelector',
      showIfSubTab: ['Max > 90°F', 'Min > 70°F'],
      props: {
        columnHeading: 'Days Over',
        minNum: 1,
        maxNum: 1,
        minValue: -200,
        maxValue: 200,
        step: 1,
        colors: ['black'],
        label: () => 'Change Threshold',
        showReset: true
      }
    },{
      component: 'thresholdRangeSelector',
      id: 'case-study-1-threshold-selector',
      showIfSubTab: ['Case Study 1'],
      props: {
        unit: '°F',
        minValue: -200,
        maxValue: 200,
        value: [65, 90],
        showReset: true,
        automaticUpdateOnEdit: false
      }
    },{
      component: 'thresholdSelector',
      id: 'cotton-quality-extreme-temperature-selector',
      showIfSubTab: ['Cotton Quality'],
      props: {
        columnHeading: '°F',
        minNum: 1,
        maxNum: 1,
        minValue: -200,
        maxValue: 200,
        step: 1,
        colors: ['black'],
        label: () => 'Extreme Temperature Threshold',
        initValue: 90,
        resetValue: 90,
        automaticUpdateOnEdit: false,
        showReset: true
      }
    },{
      component: 'thresholdRangeSelector',
      id: 'cotton-quality-optimal-range-selector',
      showIfSubTab: ['Cotton Quality'],
      props: {
        unit: '°F',
        minValue: -200,
        maxValue: 200,
        value: [75, 80],
        resetValues: [75, 80],
        showReset: true,
        automaticUpdateOnEdit: false,
        label: () => 'Optimal Temperature Range'
      }
    },{
      component: 'thresholdRangeSelector',
      id: 'cotton-quality-gdd-range-selector',
      showIfSubTab: ['Cotton Quality'],
      props: {
        unit: 'Base 60 GDDs',
        minValue: 0,
        maxValue: 20000,
        value: [800, 1700],
        resetValues: [800, 1700],
        step: 50,
        showReset: true,
        automaticUpdateOnEdit: false,
        label: () => 'Optimal GDD Range',
        maxLabel: 'First Open Bowl',
        minLabel: 'First Flower',
        resetBtnUnit: 'GDD Range'
      }
    },{
      component: 'seasonBoundsSelector',
      id: 'extreme-heat-season-bounds-selector',
      showIfSubTab: ['Max > 90°F', 'Min > 70°F', 'Case Study 1'],
      useSharedState: 'extremeHeatSeasonBoundsSelector'
    },{
      component: 'seasonBoundsSelector',
      id: 'cs3-season-bounds-selector',
      showIfSubTab: ['Cotton Quality'],
      props: {
        value: [`${TODAY.getFullYear()}-04-01`, `${TODAY.getFullYear()}-10-31`]
      }
    }]
  },
  componentFns: {
    title: ({selectedSubTab, display, overlayDataDate, chartData}) => {
      try {
        let sdate, edate, type, datesArr;
        if (selectedSubTab === 'Case Study 1' || selectedSubTab === 'Cotton Quality') {
          type = selectedSubTab;
          datesArr = chartData.timeseries.dates;
        } else {
          type = `Days With ${selectedSubTab.slice(0,3)} Over ${selectedSubTab.slice(6,8)}`;
          datesArr = chartData.timeseries.dates;
        }

        if (display === 'map') {
          const dateObj = parseISO(overlayDataDate);
          sdate = format(new Date(dateObj.getFullYear(), 0, 1), 'LLL do, yyyy');
          edate = format(parseISO(overlayDataDate), 'LLL do, yyyy');
        } else {
          sdate = format(parseISO(datesArr[0]), 'LLL do, yyyy');
          edate = format(parseISO(datesArr.slice(-1)[0]), 'LLL do, yyyy');
        }

        return {
          type,
          sdate,
          edate
        };
      } catch {
        return null;
      }
    },
    aboveChartTextBlock: ({ selectedSubTab, display }) => {
      return (display === 'chart' && selectedSubTab === 'Cotton Quality') ? 'cotton-quality_above-charts.md' : null;
    }
  },
  textPanelInfo: [{
    name: 'Data Summary',
    onlyShowIfChart: true,
    onlyShowIfSubTab: ['Cotton Quality'],
    generateMarkdown: ({ chartData }) => {
      try {
        const toDateNormal = Math.round(chartData.extremeYearly.toDate.slice(0, -1).reduce((a,b) => a + b) / (chartData.extremeYearly.toDate.length - 1));
        const extremeThreshold = chartData.extremeThreshold[0] + '°F';

        const idxOf2000 = chartData.extremeYearly.years.findIndex(y => y === 2000);
        const arr1951To1999 = chartData.extremeYearly.fullSeason.slice(0, idxOf2000);
        const arr2000ToPresent = chartData.extremeYearly.fullSeason.slice(idxOf2000);

        const tempAvg1951To1999 = averageArray(arr1951To1999);
        const tempAvg2000ToPresent = averageArray(arr2000ToPresent);
        const tempStayedSame = tempAvg1951To1999 === tempAvg2000ToPresent ? 'remained constant' : null;
        const tempDiff = Math.round((tempAvg1951To1999 - tempAvg2000ToPresent) * 100) / 100;
        const tempIncOrDec = (tempDiff > 0 ? 'decreased' : 'increased') + ' by ' + Math.abs(tempDiff) + '%';

        const cntGtLowerOpt1951To1999 = countAbove(arr1951To1999, chartData.optimalThresholds[0]);
        const cntGtUpperOpt1951To1999 = countAbove(arr1951To1999, chartData.optimalThresholds[1]);
        const pctInOpt1951To1999 = Math.round((cntGtLowerOpt1951To1999 - cntGtUpperOpt1951To1999) / arr1951To1999.length * 100) / 100;

        const cntGtLowerOpt2000ToPresent = countAbove(arr2000ToPresent, chartData.optimalThresholds[0]);
        const cntGtUpperOpt2000ToPresent = countAbove(arr2000ToPresent, chartData.optimalThresholds[1]);
        const pctInOpt2000ToPresent = Math.round((cntGtLowerOpt2000ToPresent - cntGtUpperOpt2000ToPresent) / arr2000ToPresent.length * 100) / 100;

        const pctStayedSame = pctInOpt1951To1999 === pctInOpt2000ToPresent ? 'remained constant' : null;
        const pctDiff = Math.round((pctInOpt1951To1999 - pctInOpt2000ToPresent) * 100) / 100;
        const pctIncOrDec = pctDiff > 0 ? 'decreased' : 'increased';

        const currentDaysAboveExtreme = countAbove(chartData.timeseries.current, chartData.extremeThreshold[0]);
        
        
        const lastDataDate = chartData.timeseries.dates.slice(-1)[0];
        const currentYear = Math.min(parseInt(lastDataDate.slice(0,4)), parseInt(chartData.seasonBounds[1].slice(0,4)));

        const seasonEnded = lastDataDate.slice(5,10) === chartData.seasonBounds[1].slice(5,10);
        let ending = '';
        if (seasonEnded) {
          ending = `During the ${currentYear} season, ${currentDaysAboveExtreme} days exceeded ${extremeThreshold}. Temperatures averaged `
        } else {
          ending = `So far, during the ${currentYear} season, ${currentDaysAboveExtreme} days have exceeded ${extremeThreshold}. Temperatures are averaging `;
        }

        const currentAvgTemp = averageArray(chartData.timeseries.current);
        let currentTempTrend = 'within';
        if (currentAvgTemp < chartData.optimalThresholds[0]) {
          currentTempTrend = 'colder than';
        } else if (currentAvgTemp > chartData.optimalThresholds[1]) {
          currentTempTrend = 'warmer than';
        }
        ending += ` ${currentTempTrend} the optimal range.`;
        
        return `\nSince 1951 an average of ${toDateNormal} days above ${extremeThreshold} have occurred each year. Since 2000, the number of annual ${extremeThreshold} exceedances has ${tempStayedSame || tempIncOrDec} compared to the 1951-1999 period.\nFrom 1951-1999 ,${pctInOpt1951To1999}% of the growing seasons had temperatures within the optimal range, this percentage has ${pctStayedSame || pctIncOrDec} in more recent years. From 2000-present temperatures were optimal in ${pctInOpt2000ToPresent}% of the growing seasons.\n${ending}`;
      } catch {
        return '';
      }
    },
  },{
    name: 'Strategies to Optimize Cotton Quality',
    markdownFilename: 'cotton-quality_strategies.md'
  }]
};

export default info;
export const extremeHeatSharedStateDefinitions = {
  'extremeHeatChartSelector': {
    options: info.chartInfo.charts,
    initValue: info.chartInfo.charts
  },
  'extremeHeatThresholdSelector': {
    onNavChange: (oldValue, { newSubTab }) => {
      const newValue = parseInt(newSubTab.slice(6,8));
      return isNaN(newValue) ? oldValue : [newValue];
    },
    initValue: ({ selectedSubTab }) => ([parseInt(selectedSubTab.slice(6,8))])
  },
  'extremeHeatSeasonBoundsSelector': {
    persist: false,
    initValue: [`${TODAY.getFullYear()}-01-01`, format(TODAY, 'yyyy-MM-dd')]
  },
}