import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import { parseISO, format } from 'date-fns';

import TooltipSeason from '../../components/tooltip-season/tooltip-season.component';

import roundXDigits from '../round';

const COLOR = '#7cb5ec';
const AVG_COLOR = '#436d96';

export default function calcOnTodayValueAmountOptions(chartData, address, dataName) {
  const min = Math.min(...chartData.onToday.values.filter((val) => val));
  const max = Math.max(...chartData.onToday.values);
  const maxData = chartData.onToday.values.map((days) => {
    const obj = {
      y: days,
      color: COLOR,
    };

    if (days === min) {
      obj.color = 'red';
    } else if (days === max) {
      obj.color = 'blue';
    }

    return obj;
  });

  const avg_max = Math.round(
    chartData.onToday.values.reduce((acc, val) => (acc += val)) /
      chartData.onToday.values.length
  );

  return {
    chart: {
      marginRight: 20,
    },
    title: {
      text: `Seasonal ${dataName} Achieved`,
    },
    subtitle: {
      text: `Location: ${address}`,
    },
    series: [
      {
        data: maxData,
        name: `Maximum ${dataName}`,
        color: COLOR,
        zIndex: 1,
      },
      {
        data: new Array(chartData.onToday.subsetSize - 1)
          .fill(null)
          .concat(chartData.onToday.valuesRunningAverage),
        name: '10-year Running Average',
        color: AVG_COLOR,
      },
    ],
    xAxis: {
      categories: chartData.years,
      crosshair: {
        color: 'rgb(220,220,220)',
        width: 0.5,
      },
      title: {
        text: 'Season End Year',
      },
    },
    yAxis: [
      {
        min,
        max,
        startOnTick: false,
        endOnTick: false,
        tickAmount: 5,
        gridLineWidth: 0,
        title: {
          text: dataName,
          style: {
            color: COLOR,
          },
        },
        labels: {
          style: {
            fontFamily:
              'Roboto, "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif',
          },
        },
        plotLines: [
          {
            color: 'rgb(200,200,200)',
            label: {
              text: 'Avg. Max',
              align: 'right',
              style: {
                color: 'rgb(150,150,150)',
              },
            },
            value: avg_max,
          },
        ],
      },
    ],
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      outside: true,
      useHTML: true,
      backgroundColor: 'white',
      formatter: function () {
        if (!this || !this.points) return '';

        const point = this.points[0];
        const chillUnits = point.y;
        const year = point.x;
        const dateOccurred = chartData.onToday.dateOccurred;

        const diffFromAvg = chillUnits - avg_max;
        let relativeToAverage = '';
        if (diffFromAvg > 0) {
          relativeToAverage = '+';
        }

        const avg = this.points[1];

        return renderToStaticMarkup(
          <div className='max-chill-tooltip-content-container'>
            <TooltipSeason year={year} color={COLOR} />

            <div className='mc-occurred'>
              Occurred on {format(parseISO(dateOccurred), 'MMM do')}
            </div>

            <div className='mc-container'>
              <div className='mc-number'>{roundXDigits(chillUnits, 1)}</div>
              <div className='mc-units'>{dataName}</div>
            </div>

            <div className='mc-container'>
              <div className='mc-number'>
                {relativeToAverage}
                {roundXDigits(diffFromAvg, 1)}
              </div>
              <div className='mc-units'>{dataName} from average</div>
            </div>

            {avg && (
              <div className='mc-container'>
                <div className='mc-number'>{avg.y}</div>
                <div className='mc-units'>
                  {year + 1 - chartData.onToday.subsetSize}-{year} average
                </div>
              </div>
            )}
          </div>
        );
      },
    },
  };
}
