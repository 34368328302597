import ListSelector, { defaultProps as listDefaultProps } from "./list-selector.component";
import DepartureSelector, { defaultProps as departureDefaultProps } from "./departure-selector.component";
import ThresholdSelector, { defaultProps as thresholdDefaultProps } from "./threshold-selector/threshold-selector.component";
import ThresholdRangeSelector, { defaultProps as thresholdRangeDefaultProps } from "./threshold-range-selector/threshold-range-selector.component";
import DateSelector, { defaultProps as dateDefaultProps } from "./date-selector.component";
import SeasonBoundsSelector, { defaultProps as seasonBoundsDefaultProps } from "./season-bounds-selector.component";
import SelectSelector, { defaultProps as selectDefaultProps } from "./select-selector.component";
import TemporalSelector, { defaultProps as temporalDefaultProps } from "./temporal-selector.component";

const optionsSelectorInformation = {
  listSelector: {
    component: ListSelector,
    defaultProps: listDefaultProps
  },
  departureSelector: {
    component: DepartureSelector,
    defaultProps: departureDefaultProps
  },
  thresholdSelector: {
    component: ThresholdSelector,
    defaultProps: thresholdDefaultProps
  },
  thresholdRangeSelector: {
    component: ThresholdRangeSelector,
    defaultProps: thresholdRangeDefaultProps
  },
  dateSelector: {
    component: DateSelector,
    defaultProps: dateDefaultProps
  },
  seasonBoundsSelector: {
    component: SeasonBoundsSelector,
    defaultProps: seasonBoundsDefaultProps
  },
  selectSelector: {
    component: SelectSelector,
    defaultProps: selectDefaultProps
  },
  temporalSelector: {
    component: TemporalSelector,
    defaultProps: temporalDefaultProps
  }
}

export default optionsSelectorInformation;