import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import TooltipSeason from '../../components/tooltip-season/tooltip-season.component';

const BLUE_COLOR = '#6da9f7';
const RED_COLOR = '#d45959';
const GREY_COLOR = '#adadad';
const GREEN_COLOR = '#16aa2e';

function calcDailyPrecipOptions(chartData, address, threshold) {
  const timeseries = [...chartData.timeseries.current];
  while (timeseries.length && timeseries[timeseries.length - 1] === null) {
    timeseries.pop();
  }
  const dates = chartData.timeseries.dates.slice(0, timeseries.length).map(str => str.slice(5));
  const min = 0;
  const max = Math.max(...timeseries, threshold);

  return {
    chart: {
      marginRight: 20,
      type: 'column'
    },
    title: {
      text: 'Precipitation Throughout Season',
    },
    subtitle: {
      text: `Location: ${address}`,
    },
    series: [
      {
        data: timeseries,
        name: 'Current Season',
        groupPadding: 0,
        pointPadding: 0,
        zones: [{
          value: threshold,
          color: BLUE_COLOR
        },{
          color: RED_COLOR
        }]
      }
    ],
    xAxis: {
      categories: dates,
      crosshair: {
        color: 'rgb(220,220,220)',
        width: 0.5,
      },
      title: {
        text: 'Day of Year',
      },
    },
    yAxis: [
      {
        min,
        max,
        startOnTick: false,
        endOnTick: false,
        tickAmount: 5,
        gridLineWidth: 0,
        title: {
          text: 'Precipitation (inches)',
        },
        labels: {
          style: {
            fontFamily:
              'Roboto, "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif',
          },
        },
        plotLines: [
          {
            color: 'rgb(200,200,200)',
            value: threshold,
            label: {
              text: `${threshold} inches`,
              align: 'right',
              style: {
                color: 'rgb(150,150,150)',
              },
            },
          },
        ],
      },
    ],
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      outside: true,
      useHTML: true,
      backgroundColor: 'white',
      formatter: function () {
        if (!this || !this.points) return '';

        const point = this.points[0];
        const pcpn = point.y;
        const date = point.x;

        return renderToStaticMarkup(
          <div className='max-chill-tooltip-content-container'>
            <TooltipSeason year={date} color={pcpn >= threshold ? RED_COLOR : BLUE_COLOR} />

            <div className='mc-container'>
              <div className='mc-number'>{Math.round(pcpn * 100) / 100}</div>
              <div className='mc-units'>inches</div>
            </div>
          </div>
        );
      },
    },
  };
}

function calcAnnualPrecipOptions(chartData, address, seasonBounds) {
  const { years, toDate, toDateDate, fullSeason } = chartData.yearlySeasons;
  const min = 0;
  const max = Math.max(...toDate);

  if (typeof seasonBounds === 'function') {
    seasonBounds = seasonBounds();
  }

  const seasonSDate = seasonBounds[0].slice(5,10).replace("-","/");
  const seasonEDate = seasonBounds[1].slice(5,10).replace("-","/");

  const finalCurrentYearDate = chartData.timeseries.dates.slice(-1)[0];
  let finalDataDate = new Date(finalCurrentYearDate) < new Date(toDateDate) ? finalCurrentYearDate : toDateDate;
  finalDataDate = finalDataDate.slice(5,10).replace('-','/');

  return {
    chart: {
      marginRight: 20,
      type: 'column'
    },
    plotOptions: {
      column: {
        grouping: false
      }
    },
    title: {
      text: `Occurrences for the Season: ${seasonSDate} to ${seasonEDate}`,
    },
    subtitle: {
      text: `Location: ${address}`,
    },
    series: [
      {
        data: fullSeason,
        name: `At End of Season (${seasonEDate})`,
        color: GREY_COLOR,
        opacity: 0.6,
        borderColor: GREY_COLOR,
        groupPadding: 0
      },{
        data: toDate,
        name: 'To Date',
        color: BLUE_COLOR,
        pointPadding: 0.125
      }
    ],
    xAxis: {
      categories: years,
      crosshair: {
        color: 'rgb(220,220,220)',
        width: 0.5,
      },
      title: {
        text: 'Year',
      },
    },
    yAxis: [
      {
        min,
        max,
        startOnTick: false,
        endOnTick: false,
        tickAmount: 5,
        gridLineWidth: 0,
        title: {
          text: 'Number of Occurrences',
        },
        labels: {
          style: {
            fontFamily:
              'Roboto, "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif',
          },
        },
        plotLines: [
          {
            color: 'rgb(150,150,150)',
            value: Math.round(toDate.slice(0, -1).reduce((a,b) => a + b) / (toDate.length - 1)),
            label: {
              text: 'To Date Normal',
              style: {
                color: 'rgb(80,80,80)',
              },
            },
            zIndex: 4
          },
        ],
      },
    ],
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      outside: true,
      useHTML: true,
      backgroundColor: 'white',
      formatter: function () {
        if (!this || !this.points) return '';

        const date = this.points[0].x;
        const isCurrentYear = date === years.slice(-1)[0];
        
        return renderToStaticMarkup(
          <div className='max-chill-tooltip-content-container'>
            <TooltipSeason year={date} color={BLUE_COLOR} />

            {this.points.reverse().map(point => (
              <div key={point.y} className='mc-container' style={{ justifyContent: 'space-between' }}>
                <div className='mc-units'>{point.series.name === 'To Date' ? `To ${isCurrentYear ? finalDataDate : toDateDate.slice(5,10).replace('-','/')}` : point.series.name}: </div>
                <div className='mc-number'>{point.y}</div>
              </div>
            ))}
          </div>
        );
      },
    },
  };
}

function calcAnnualDryRunOptions(chartData, address) {
  const { years, longestDryRun } = chartData.yearlySeasons;
  const min = 0;
  const max = Math.max(...longestDryRun);

  return {
    chart: {
      marginRight: 20,
      type: 'column'
    },
    plotOptions: {
      column: {
        grouping: false
      }
    },
    title: {
      text: 'Longest Run of Dry Days Annually',
    },
    subtitle: {
      text: `Location: ${address}`,
    },
    series: [
      {
        data: longestDryRun,
        name: 'Longest Dry Run',
        color: BLUE_COLOR
      }
    ],
    xAxis: {
      categories: years,
      crosshair: {
        color: 'rgb(220,220,220)',
        width: 0.5,
      },
      title: {
        text: 'Year',
      },
    },
    yAxis: [
      {
        min,
        max,
        startOnTick: false,
        endOnTick: false,
        tickAmount: 5,
        gridLineWidth: 0,
        title: {
          text: 'Number of Days <0.01 inches',
        },
        labels: {
          style: {
            fontFamily:
              'Roboto, "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif',
          },
        },
      },
    ],
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      outside: true,
      useHTML: true,
      backgroundColor: 'white',
      formatter: function () {
        if (!this || !this.points) return '';

        const date = this.points[0].x;
        return renderToStaticMarkup(
          <div className='max-chill-tooltip-content-container'>
            <TooltipSeason year={date} color={BLUE_COLOR} />

            {this.points.reverse().map(point => (
              <div key={point.y} className='mc-container' style={{ justifyContent: 'space-between' }}>
                <div className='mc-units'>{point.series.name}: </div>
                <div className='mc-number'>{point.y}</div>
              </div>
            ))}
          </div>
        );
      },
    },
  };
}

function calcTimeseriesPrecipOptions(chartData, address) {
  const { dates, current, normal, highest, lowest } = chartData.timeseries;
  
  let sum = 0;
  const timeseries = current.reduce((arr,pcpn) => {
    sum += pcpn;
    arr.push(sum);
    return arr;
  }, []);
  const formattedDates = dates.map(d => d.slice(5,10));
  const min = 0;
  const max = highest.data.slice(-1)[0];
  
  return {
    chart: {
      marginRight: 20,
    },
    title: {
      text: 'Precipitation Accumulation Throughout Season',
    },
    subtitle: {
      text: `Location: ${address}`,
    },
    series: [
      {
        data: timeseries,
        name: 'Current Season',
        color: GREEN_COLOR,
        type: 'area',
        fillOpacity: 0.2,
        zIndex: 1
      },{
        data: highest.data,
        name: `Highest (${highest.year})`,
        color: RED_COLOR,
        zIndex: 2
      },{
        data: normal,
        name: 'Normal',
        color: GREY_COLOR,
        zIndex: 2
      },{
        data: lowest.data,
        name: `Lowest (${highest.year})`,
        color: BLUE_COLOR,
        zIndex: 2
      }
    ],
    xAxis: {
      categories: formattedDates,
      crosshair: {
        color: 'rgb(220,220,220)',
        width: 0.5,
      },
      title: {
        text: 'Day of Year',
      },
    },
    yAxis: [
      {
        min,
        max,
        startOnTick: false,
        endOnTick: false,
        tickInterval: 10,
        gridLineWidth: 0,
        title: {
          text: 'Precipitation (inches)',
        },
        labels: {
          style: {
            fontFamily:
              'Roboto, "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif',
          },
        },
      },
    ],
    tooltip: {
      shared: true,
      outside: true,
      useHTML: true,
      backgroundColor: 'white',
      formatter: function () {
        if (!this || !this.points) return '';
        return renderToStaticMarkup(
          <div className='max-chill-tooltip-content-container'>
            <TooltipSeason year={this.x} color={BLUE_COLOR} />

            {this.points.map(({ y, series }) => 
              <div key={series.name}>
                <div className='mc-season'>{series.name}</div>
                <div className='mc-container'>
                  <div className='mc-number'>{Math.round(y * 100) / 100}</div>
                  <div className='mc-units'> inches</div>
                </div>
              </div>
            )}
          </div>
        );
      },
    },
  };
}

export { calcDailyPrecipOptions, calcAnnualPrecipOptions, calcAnnualDryRunOptions, calcTimeseriesPrecipOptions };