import React, { useContext } from 'react';

import { GlobalContext } from './contexts/global.context';

import Header from './components/header/header.component';
import Nav from './components/nav/nav.component';
import Footer from './components/footer/footer.component';
import Map from './components/map/map.component';
import Charts from './components/charts/charts.component';
import OptionsPanel from './components/options-panel/options-panel.component';
import Loading from './components/loading/loading.component';
import Legend from './components/legend/legend.component';
import TextPanel from './components/text-panel/text-panel.component';
import TextBlock from './components/text-block/text-block.component';

import { useScrollWithShadow } from './hooks/useScrollWithShadow';

import './App.scss';

function App() {
  const { display, isLoading } = useContext(GlobalContext);
  const { boxShadow, onScrollHandler } = useScrollWithShadow();

  return (
    <div className='App'>
      <div>
        <Header />
        <Nav />
      </div>

      <div onScroll={onScrollHandler} style={{ position: 'relative', overflowY: 'auto' }}>
        <main>
          <TextBlock blockName='aboveChartTextBlock' />

          <div className='map-content-container'>
            <OptionsPanel />
            <div className='main-panel'>
              <Map />
              <Legend />
              {display === 'chart' && <Charts />}
            </div>
          </div>
          <div className="text-block">
            <TextPanel />
          </div>
          {isLoading && <Loading />}
        </main>
        
        <Footer />

        <div className='nav-shadow' style={{ boxShadow }}></div>
      </div>
    </div>
  );
}

export default App;
