import { renderToStaticMarkup } from 'react-dom/server';

import TooltipSeason from '../../components/tooltip-season/tooltip-season.component';

import { isPastThreshold } from '../extremes-utilities';
import { calculateSeasonYear, isInSeason } from "../seasonCalculations";

const BLUE_COLOR = '#6da9f7';
const GREEN_COLOR = '52, 150, 45';
const RED_COLOR = '212, 89, 89';
const GREY_COLOR = '#adadad';

export function calcCottonQualityTimeseriesOptions(chartData, address) {
  console.log(chartData, address);

  const min = Math.min(...chartData.timeseries.current, chartData.extremeThreshold[0] - 5);
  const max = Math.max(...chartData.timeseries.current, chartData.extremeThreshold[0] + 5);

  return {
    chart: {
      marginRight: 20
    },
    title: {
      text: 'Daily Average Temperatures',
    },
    subtitle: {
      text: `Location: ${address}`,
    },
    series: [
      {
        data: chartData.timeseries.current,
        name: 'Current Season',
        zones: [{
          value: chartData.extremeThreshold[0],
          color: GREY_COLOR
        },{
          color: `rgb(${RED_COLOR})`
        }]
      }
    ],
    xAxis: {
      categories: chartData.timeseries.dates.map(d => d.slice(5,10).replace('-', '/')),
      title: {
        text: 'Day of Year',
      },
      plotLines: [{
        color: 'rgb(150,150,150)',
        value: chartData.firstFlowerIdx,
        label: {
          text: 'First Flower',
          style: {
            color: 'rgb(80,80,80)',
          },
        },
        dashStyle: 'dash'
      },{
        color: 'rgb(150,150,150)',
        value: chartData.firstOpenBollIdx,
        label: {
          text: 'First Open Bowl',
          style: {
            color: 'rgb(80,80,80)',
          },
        },
        dashStyle: 'dash'
      }],
    },
    yAxis: [
      {
        min,
        max,
        title: {
          text: 'Avg. Temperature (°F)',
        },
        labels: {
          style: {
            fontFamily:
              'Roboto, "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif',
          },
        },
        plotBands: [{
          from: chartData.extremeThreshold[0],
          to: Infinity,
          color: `rgba(${RED_COLOR}, 0.3)`
        }]
      },
    ],
    legend: {
      enabled: false,
    },
  };
}

export function calcCottonQualityAnnual1Options(chartData, address, seasonBounds) {
  console.log(chartData, address, seasonBounds);

  const { years, toDate, toDateDate, fullSeason } = chartData.extremeYearly;
  const min = 0;
  const max = Math.max(...fullSeason, ...toDate);

  if (typeof seasonBounds === 'function') {
    seasonBounds = seasonBounds();
  }

  const seasonSDate = seasonBounds[0].slice(5,10).replace("-","/");
  const seasonEDate = seasonBounds[1].slice(5,10).replace("-","/");

  const finalCurrentYearDate = chartData.timeseries.dates.slice(-1)[0];
  let finalDataDate = new Date(finalCurrentYearDate) < new Date(toDateDate) ? finalCurrentYearDate : toDateDate;
  finalDataDate = finalDataDate.slice(5,10).replace('-','/');

  const toDateNormal = Math.round(toDate.slice(0, -1).reduce((a,b) => a + b) / (toDate.length - 1));

  return {
    chart: {
      marginRight: 20,
      type: 'column'
    },
    plotOptions: {
      column: {
        grouping: false
      }
    },
    title: {
      text: `Occurrences Exceeding Extreme Temperature Threshold for the Season: ${seasonSDate} to ${seasonEDate}`,
    },
    subtitle: {
      text: `Location: ${address}`,
    },
    series: [
      {
        data: fullSeason,
        name: 'At End of Season',
        color: GREY_COLOR,
        opacity: 0.6,
        borderColor: GREY_COLOR,
        groupPadding: 0
      },{
        data: toDate,
        name: 'To Date',
        color: `rgb(${RED_COLOR})`,
        pointPadding: 0.125,
        zones: [{
          value: toDateNormal,
          color: GREY_COLOR
        },{
          color: `rgb(${RED_COLOR})`
        }]
      }
    ],
    xAxis: {
      categories: years,
      crosshair: {
        color: 'rgb(220,220,220)',
        width: 0.5,
      },
      title: {
        text: 'Year',
      },
    },
    yAxis: [
      {
        min,
        max,
        startOnTick: false,
        endOnTick: false,
        tickAmount: 5,
        gridLineWidth: 0,
        title: {
          text: 'Number of Occurrences',
        },
        labels: {
          style: {
            fontFamily:
              'Roboto, "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif',
          },
        },
        plotLines: [
          {
            color: 'rgb(150,150,150)',
            value: toDateNormal,
            label: {
              text: 'To Date Normal',
              style: {
                color: 'rgb(80,80,80)',
              },
            },
            zIndex: 4
          },
        ],
      },
    ],
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      outside: true,
      useHTML: true,
      backgroundColor: 'white',
      formatter: function () {
        if (!this || !this.points) return '';

        const date = this.points[0].x;
        const isCurrentYear = date === years.slice(-1)[0];

        return renderToStaticMarkup(
          <div className='max-chill-tooltip-content-container'>
            <TooltipSeason year={date} color={BLUE_COLOR} />

            {this.points.reverse().map(point => (
              <div key={point.y} className='mc-container' style={{ justifyContent: 'space-between' }}>
                <div className='mc-units'>{point.series.name === 'To Date' ? `To ${isCurrentYear ? finalDataDate : toDateDate.slice(5,10).replace('-','/')}` : point.series.name}: </div>
                <div className='mc-number'>{point.y}</div>
              </div>
            ))}
          </div>
        );
      },
    },
  };
}

export function calcCottonQualityTimeseries2Options(chartData, address) {
  console.log(chartData, address);

  const { years, fullSeason } = chartData.rangeYearly;
  const min = Math.min(...fullSeason, chartData.optimalThresholds[0] - 5);
  const max = Math.max(...fullSeason, chartData.optimalThresholds[1] + 5);

  return {
    chart: {
      marginRight: 20
    },
    title: {
      text: 'Average Temperature from First Flower to First Boll',
    },
    subtitle: {
      text: `Location: ${address}`,
    },
    series: [
      {
        data: fullSeason,
        name: 'Average Temperature',
        zones: [{
          value: chartData.optimalThresholds[0],
          color: BLUE_COLOR
        },{
          value: chartData.optimalThresholds[1],
          color: `rgb(${GREEN_COLOR})`
        },{
          color: `rgb(${RED_COLOR})`
        }]
      }
    ],
    xAxis: {
      categories: years,
      crosshair: {
        color: 'rgb(220,220,220)',
        width: 0.5,
      },
      title: {
        text: 'Year',
      },
    },
    yAxis: [
      {
        min,
        max,
        startOnTick: false,
        endOnTick: false,
        tickAmount: 5,
        gridLineWidth: 0,
        title: {
          text: 'Avg. Temperature (°F)',
        },
        labels: {
          style: {
            fontFamily:
              'Roboto, "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif',
          },
        },
        plotBands: [{
          from: chartData.optimalThresholds[0],
          to: chartData.optimalThresholds[1],
          color: `rgba(${GREEN_COLOR}, 0.1)`
        }]
      },
    ],
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      outside: true,
      useHTML: true,
      backgroundColor: 'white',
      formatter: function () {
        if (!this || !this.points) return '';

        const date = this.points[0].x;

        return renderToStaticMarkup(
          <div className='max-chill-tooltip-content-container'>
            <TooltipSeason year={date} color={BLUE_COLOR} />

            {this.points.reverse().map(point => (
              <div key={point.y} className='mc-container' style={{ justifyContent: 'center' }}>
                <div className='mc-number'>{point.y}</div>
                <div className='mc-units'>°F</div>
              </div>
            ))}
          </div>
        );
      },
    },
  };
}


function average(v1, v2) {
  return Math.round((v1 + v2) / 2 * 100) / 100;
}

function calculateGddAccumulations(gdds) {
  const accumulations = [];
  let sum = 0;
  gdds.forEach(([date, gdd]) => {
    sum += gdd;
    accumulations.push(sum);
  });
  return accumulations;
}

function findRangeIndices(gdds, thresholds) {
  const firstFlowerIdx = gdds.findIndex(gdd => gdd >= thresholds[0]);
  const firstOpenBollIdx = gdds.findIndex(gdd => gdd >= thresholds[1]);
  return { firstFlowerIdx, firstOpenBollIdx };
}

function calculateAverageTemp(temps) {
  let sum = 0;
  let count = 0;
  temps.forEach(([date, mint, maxt]) => {
    sum += mint;
    sum += maxt;
    count += 2;
  });
  return Math.round(sum / count * 100) / 100;
}

function calculateAverageTempWithinGddRange(seasonTemps, seasonGdds, gddThresholds) {
  const accumulations = calculateGddAccumulations(seasonGdds);
  const rangeIndices = findRangeIndices(accumulations, gddThresholds);
  
  let avgTemp = null;
  if (rangeIndices.firstFlowerIdx !== -1) {
    avgTemp = calculateAverageTemp(seasonTemps.slice(rangeIndices.firstFlowerIdx, rangeIndices.firstOpenBollIdx === -1 ? seasonTemps.length : rangeIndices.firstOpenBollIdx + 1));
  }

  return { avgTemp, ...rangeIndices };
}

export function refreshCottonQualityChartData(temps, gdds, gddThresholds, optimalThresholds, extremeThreshold, seasonBounds, TODAY) {
  const compFn = isPastThreshold(extremeThreshold, 'above');
  const dataIdx = 2;    // maxt

  const newChartData = {
    seasonBounds,
    gddThresholds,
    optimalThresholds,
    extremeThreshold,
    firstFlowerIdx: null,
    firstOpenBollIdx: null,
    timeseries: {
      dates: [],
      current: [],
    },
    rangeYearly: {
      years: [],
      fullSeason: []
    },
    extremeYearly: {
      years: [],
      toDate: [],
      fullSeason: []
    }
  };
  
  const seasonStartDay = seasonBounds[0].slice(5,10);
  const seasonEndDay = seasonBounds[1].slice(5,10);
  const today = isInSeason(TODAY.toISOString(), seasonBounds[0], seasonBounds[1]) ? TODAY.toISOString().slice(0,10) : seasonBounds[1];
  newChartData.extremeYearly.toDateDate = today.slice(0,10);
  newChartData.rangeYearly.toDateDate = today.slice(0,10);
  
  let idxYearStart = null;
  let lastIdxProcessed = 0;
  for (let i = 0; i < temps.length; i++) {
    const strDate = temps[i][0];
    const isToday = strDate.slice(5,10) === today.slice(5,10);

    if (isToday && idxYearStart !== null) {
      // To date
      const seasonTempData = temps.slice(idxYearStart, i + 1);
      newChartData.extremeYearly.toDate.push(seasonTempData.filter((dayData) => compFn(dayData[dataIdx])).length);
    }
    
    if (strDate.slice(5) === seasonEndDay && idxYearStart !== null) {
      // End of season
      const seasonTempData = temps.slice(idxYearStart, i + 1);
      newChartData.extremeYearly.years.push(parseInt(strDate.slice(0,4)));
      newChartData.extremeYearly.fullSeason.push(seasonTempData.filter((dayData) => compFn(dayData[dataIdx])).length);
      const seasonGddData = gdds.slice(idxYearStart, i + 1);
      const { avgTemp, firstFlowerIdx, firstOpenBollIdx } = calculateAverageTempWithinGddRange(seasonTempData, seasonGddData, gddThresholds);
      newChartData.rangeYearly.years.push(parseInt(strDate.slice(0,4)));
      newChartData.rangeYearly.fullSeason.push(avgTemp);
      newChartData.firstFlowerIdx = firstFlowerIdx;
      newChartData.firstOpenBollIdx = firstOpenBollIdx;
      lastIdxProcessed = i;
    }
    
    if (strDate.slice(5) === seasonStartDay) {
      // Start of season
      idxYearStart = i;
    }
  }

  if (idxYearStart !== null && (lastIdxProcessed === null || idxYearStart > lastIdxProcessed)) {
    // Finish partial season for yearly and to use as timeseries
    const currentSeasonTempData = temps.slice(idxYearStart);
    const currentSeasonGddData = gdds.slice(idxYearStart);
    const seasonYear = calculateSeasonYear(currentSeasonTempData[currentSeasonTempData.length - 1][0], seasonBounds[0], seasonBounds[1]);
    newChartData.extremeYearly.years.push(seasonYear);
    newChartData.extremeYearly.toDate.push(currentSeasonTempData.filter((dayData) => compFn(dayData[dataIdx])).length);
    newChartData.extremeYearly.fullSeason.push(null);
    const { avgTemp, firstFlowerIdx, firstOpenBollIdx } = calculateAverageTempWithinGddRange(currentSeasonTempData, currentSeasonGddData, gddThresholds);
    newChartData.rangeYearly.years.push(seasonYear);
    newChartData.rangeYearly.fullSeason.push(avgTemp);
    newChartData.firstFlowerIdx = firstFlowerIdx;
    newChartData.firstOpenBollIdx = firstOpenBollIdx;
    currentSeasonTempData.forEach(dayData => {
      newChartData.timeseries.dates.push(dayData[0]);
      newChartData.timeseries.current.push(average(dayData[1], dayData[2]));
    });
  } else if (idxYearStart !== null && lastIdxProcessed !== null && idxYearStart < lastIdxProcessed) {
    // If using last completed season for timeseries only
    const currentSeasonTempData = temps.slice(idxYearStart, lastIdxProcessed + 1);
    currentSeasonTempData.forEach(dayData => {
      newChartData.timeseries.dates.push(dayData[0]);
      newChartData.timeseries.current.push(average(dayData[1], dayData[2]));
    });
  }

  return {
    newChartData,
    newCurrentSeasonChartData: null
  };
}