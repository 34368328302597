import React from 'react';
import { isLeapYear, parseISO, formatISO } from 'date-fns';

import Accordion from '../accordion/accordion.component';
import {  BaseSeasonBoundsSelector } from './season-bounds-selector.component';
import { BaseSelectSelector } from './select-selector.component';

function temporalFromSeasonBounds(temporalOptions, seasonBounds) {
  const result = temporalOptions.find(({ start, end }) => {
    const hasStart = start.includes(seasonBounds[0].slice(5,10));
    const hasEnd = end.includes(seasonBounds[1].slice(5,10));
    return (hasStart && hasEnd) || (hasStart && end.length === 0)
  });
  if (result) {
    return result.value;
  } else {
    return 'seasonal';
  }
}

function seasonBoundsFromTemporal(temporalOptions, selectedTemporal, currSeasonBounds) {
  const result = temporalOptions.find(({ value }) => value === selectedTemporal);
  if (result) {
    const newSeasonBounds = [];
    const year = new Date().getFullYear();
    
    let sdate;
    if (result.start.length) {
      sdate = `${year}-${result.start[0]}`;
    } else {
      sdate = currSeasonBounds[0];
    }
    if (sdate.slice(5,10) === '02-29' && !isLeapYear(parseISO(sdate))) {
      sdate = year + '-02-28';
    }
    newSeasonBounds.push(sdate);

    let edate;
    if (result.end.length) {
      edate = `${year}-${result.end.slice(-1)[0]}`;
    } else if (result.name === 'Annual') {
      edate = formatISO(new Date())
    } else {
      edate = currSeasonBounds[1];
    }
    if (edate.slice(5,10) === '02-29' && !isLeapYear(parseISO(edate))) {
      edate = year + '-02-28';
    }
    newSeasonBounds.push(edate);

    return newSeasonBounds;
  }
  return null;
}

const defaultProps = {
  value: {},
  label: 'Season Dates',
  seasonBoundsProps: {},
  temporalProps: { options: [
    { name: 'Annual', value: 'annual', start: ['01-01'], end: [] },
    { name: 'Seasonal', value: 'seasonal', start: [], end: [] },
  ] }
};

export default function TemporalSelector({
  expanded,
  handleChange,
  handleChangeSelection,
  id,
  value=defaultProps.value,
  label=defaultProps.label,
  seasonBoundsProps=defaultProps.seasonBoundsProps,
  temporalProps=defaultProps.temporalProps
}) {
  const handleTimeframeChange = (newValue, selector) => {
    const updatedValue = { [selector]: newValue };
    if (selector === 'temporal') {
      updatedValue.seasonBounds = seasonBoundsFromTemporal(temporalProps.options, newValue, value.seasonBounds);
    } else if (selector === 'seasonBounds') {
      updatedValue.temporal = temporalFromSeasonBounds(temporalProps.options, newValue);
    }
    handleChangeSelection(updatedValue);
  }

  return (
    <Accordion expanded={expanded === id} handleChange={handleChange} id={id} label={label} >
      <div style={{ marginBottom: '12px' }}>
        <BaseSelectSelector
          handleChangeSelection={(newValue) => handleTimeframeChange(newValue, 'temporal')}
          value={value.temporal}
          {...temporalProps}
        />
      </div>

      <BaseSeasonBoundsSelector
        handleChangeSelection={(newValue) => handleTimeframeChange(newValue, 'seasonBounds')}
        value={value.seasonBounds}
        {...seasonBoundsProps}
      />
    </Accordion>
  );
}

export { defaultProps };