import { renderToStaticMarkup } from 'react-dom/server';

import { temporalOptions } from '../../hooks/tab-info-files/climatological';
import TooltipSeason from '../../components/tooltip-season/tooltip-season.component';

const BLUE_COLOR = '#6da9f7';

export function calcClimatologicalOptions(chartData, address, temporalSelection) {
  const timeseries = temporalSelection === 'seasonal' ? chartData[temporalSelection].values : chartData[temporalSelection];
  const years = temporalSelection === 'seasonal' ? chartData[temporalSelection].years : chartData.years;
  
  const temporalName = temporalOptions.find(obj => obj.value === temporalSelection).name;
  let titleText;
  if (temporalName === 'Annual' || temporalName === 'Seasonal') {
    titleText = `${temporalName} Average Temperature`;
  } else {
    titleText = `Average Temperature in ${temporalName}`;
  }

  return {
    chart: {
      marginRight: 20,
    },
    title: {
      text: titleText,
    },
    subtitle: {
      text: `Location: ${address}`,
    },
    series: [
      {
        data: timeseries,
        name: 'Current Season',
        color: BLUE_COLOR
      }
    ],
    xAxis: {
      categories: years,
      crosshair: {
        color: 'rgb(220,220,220)',
        width: 0.5,
      },
      title: {
        text: 'Year',
      },
    },
    yAxis: [
      {
        gridLineWidth: 0,
        title: {
          text: 'Temperature (°F)',
        },
        labels: {
          style: {
            fontFamily:
              'Roboto, "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif',
          },
        },
      },
    ],
    tooltip: {
      shared: true,
      outside: true,
      useHTML: true,
      backgroundColor: 'white',
      formatter: function () {
        if (!this || !this.points) return '';
        return renderToStaticMarkup(
          <div className='max-chill-tooltip-content-container'>
            <TooltipSeason year={this.x} color={BLUE_COLOR} />

            {this.points.map(({ y, series }) => 
              <div key={series.name}>
                <div className='mc-container'>
                  <div className='mc-number'>{Math.round(y * 100) / 100}</div>
                  <div className='mc-units'> °F</div>
                </div>
              </div>
            )}
          </div>
        );
      },
    },
    legend: {
      enabled: false
    }
  };
}