import React, { useContext } from 'react';

import MarkdownToJsx from '../markdown-to-jsx/markdown-to-jsx.component';

import './text-block.styles.scss';

import { TabInfoContext } from '../../contexts/tab-info.context';
import { GlobalContext } from '../../contexts/global.context';

export default function TextBlock({ blockName }) {
  const { display, selectedSubTab, overlayDataDate, overlayFilename, chartData } = useContext(GlobalContext);
  const { tabsSharedState, componentFns } = useContext(TabInfoContext);

  const blockMdFileName = (componentFns && blockName in componentFns) ? componentFns[blockName]({ selectedSubTab, display, overlayDataDate, overlayFilename, tabsSharedState, chartData }) : null;

  return (
    <div className='text-block'>
      {blockMdFileName ?
        <MarkdownToJsx
          path={`${process.env.PUBLIC_URL}/markdown/${blockMdFileName}`}
        />
        :
        <React.Fragment>
          <p>Climate Indicators convey information about changing climate conditions and their impacts. Effective indicators inform practical near-term decision making, longer-term resiliency planning in addition to tracking changes through time.</p>
          <p>The map below shows the current status of a selected indicator compared to last year or if selected the long term average.</p>
          <p>Clicking on the map shows how the indicator has changed through time at a specific location.  Here the indicator can be further customized.  Resources for real-time decision making and adaptation planning can also be accessed.</p>
        </React.Fragment>
      }
    </div>
  );
}