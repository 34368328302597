import React, { useContext } from 'react';

import { TabInfoContext } from '../../contexts/tab-info.context';
import { GlobalContext } from '../../contexts/global.context';

import NavDrawer from './nav-drawer.component';
import NavBar from './nav-bar.component';

import './nav.styles.scss';

export default function Nav() {
  const { selectedTab, selectedSubTab, handleUpdate } = useContext(GlobalContext);
  const { navInfo } = useContext(TabInfoContext);

  function handleSelect(newTab, newSubTab) {
    if (newSubTab !== 'Coming soon...') {
      handleUpdate('nav', { newTab, newSubTab });
    }
  }

  return (
    <nav>
      <div id='nav-drawer'>
        <NavDrawer
          navInfo={navInfo}
          selectedGroup={selectedTab}
          selectedOption={selectedSubTab}
          handleSelect={handleSelect}
        />
      </div>

      <div id='nav-bar'>
        <NavBar
          navInfo={navInfo}
          selectedGroup={selectedTab}
          selectedOption={selectedSubTab}
          handleSelect={handleSelect}
        />
      </div>
    </nav>
  );
}