import { parseISO, format } from 'date-fns';

import { calculateSeasonYear, isInSeason } from '../../utilities/seasonCalculations';
import { calcDailyPrecipOptions, calcAnnualPrecipOptions, calcAnnualDryRunOptions, calcTimeseriesPrecipOptions } from '../../utilities/charts/precip-chart-options';
import updateOverlayFromR2 from '../../utilities/overlayUpdateFns';
import { fetchFromAcis } from '../../utilities/acis-api';
import { TODAY } from '../../contexts/constants';

function transpose(matrix) {
  const rows = matrix.length, cols = matrix[0].length;
  const grid = [];
  for (let j = 0; j < cols; j++) {
    grid[j] = Array(rows);
  }
  for (let i = 0; i < rows; i++) {
    for (let j = 0; j < cols; j++) {
      grid[j][i] = matrix[i][j];
    }
  }
  return grid;
}

const longestLessThanRun = (arr, target) => {
  let maxRunLength = 0;
  let currentRunLength = 0;

  for (let num of arr) {
    if (num < target) {
      currentRunLength++;
      maxRunLength = Math.max(maxRunLength, currentRunLength);
    } else {
      currentRunLength = 0;
    }
  }

  return maxRunLength;
}

const refreshRainfallExtremesChartData = (pcpns, threshold, seasonBounds) => {
  const newChartData = {
    timeseries: {
      dates: [],
      current: [],
      normal: [],
      highest: { year: null, data: [] },
      lowest: { year: null, data: [] }
    },
    yearlySeasons: {
      years: [],
      toDate: [],
      fullSeason: [],
      longestDryRun: []
    }
  };

  const seasonStartDay = seasonBounds[0].slice(5,10);
  const seasonEndDay = seasonBounds[1].slice(5,10);
  const today = isInSeason(TODAY.toISOString(), seasonBounds[0], seasonBounds[1]) ? TODAY.toISOString().slice(0,10) : seasonBounds[1];
  newChartData.yearlySeasons.toDateDate = today.slice(0,10);

  const dataBySeason = [];

  let idxYearStart = null;
  let lastIdxProcessed = null;
  for (let i = 0; i < pcpns.length; i++) {
    const strDate = pcpns[i][0];
    const isToday = strDate.slice(5,10) === today.slice(5,10);

    if (isToday && idxYearStart !== null) {
      const seasonData = pcpns.slice(idxYearStart, i + 1);
      newChartData.yearlySeasons.toDate.push(seasonData.filter(([_, val]) => val >= threshold).length);
    }
    
    if (strDate.slice(5) === seasonEndDay && idxYearStart !== null) {
      const seasonData = pcpns.slice(idxYearStart, i + 1);
      const seasonYear = parseInt(seasonData[seasonData.length - 1][0].slice(0,4));

      // Calculate season precip sum, store for later to calculate normals, track highest/lowest accumulation year
      let precipSum = 0;
      const precipSums = seasonData.map(([_, val]) => {
        precipSum += val;
        return precipSum;
      });
      dataBySeason.push(precipSums);
      if (newChartData.timeseries.highest.year === null || precipSum > newChartData.timeseries.highest.data.slice(-1)[0]) {
        newChartData.timeseries.highest = { year: seasonYear, data: precipSums };
      }
      if (newChartData.timeseries.lowest.year === null || precipSum < newChartData.timeseries.lowest.data.slice(-1)[0]) {
        newChartData.timeseries.lowest = { year: seasonYear, data: precipSums };
      }
      
      newChartData.yearlySeasons.years.push(seasonYear);
      newChartData.yearlySeasons.fullSeason.push(seasonData.filter(([_, val]) => val >= threshold).length);
      newChartData.yearlySeasons.longestDryRun.push(longestLessThanRun(seasonData.map(arr => arr[1]), 0.01));
      lastIdxProcessed = i;
    }

    if (strDate.slice(5) === seasonStartDay) {
      idxYearStart = i;
    }
  }

  let timeseriesIsInDataBySeason = false;
  if (idxYearStart !== null && (lastIdxProcessed === null || idxYearStart > lastIdxProcessed)) {
    const currentSeasonData = pcpns.slice(idxYearStart);
    const seasonYear = calculateSeasonYear(currentSeasonData[currentSeasonData.length - 1][0], seasonBounds[0], seasonBounds[1]);

    newChartData.yearlySeasons.years.push(seasonYear);
    newChartData.yearlySeasons.toDate.push(currentSeasonData.filter(([_, val]) => val >= threshold).length);
    newChartData.yearlySeasons.fullSeason.push(null);
    newChartData.yearlySeasons.longestDryRun.push(longestLessThanRun(currentSeasonData.map(arr => arr[1]), 0.01));
    currentSeasonData.forEach(([date, value]) => {
      newChartData.timeseries.dates.push(date);
      newChartData.timeseries.current.push(value);
    });
  } else if (idxYearStart !== null && lastIdxProcessed !== null && idxYearStart < lastIdxProcessed) {
    timeseriesIsInDataBySeason = true;
    const currentSeasonData = pcpns.slice(idxYearStart, lastIdxProcessed + 1);
    currentSeasonData.forEach(([date, value]) => {
      newChartData.timeseries.dates.push(date);
      newChartData.timeseries.current.push(value);
    });
  }

  // Remove current season from dataBySeason if it is in there, so it isnt included in the normals
  if (timeseriesIsInDataBySeason) {
    dataBySeason.pop();
  }

  // If 2/29 is in season range, handle leap years:
  //   If timeseries includes 2/29, any non leap year gets 3/1 duplicated
  //   If timeseries does not include 2/29, any leap year gets 2/29 deleted
  const indexOfLeapDay = newChartData.timeseries.dates.indexOf(`${newChartData.timeseries.dates[0].slice(0,4)}-02-29`);
  const seasonLengths = dataBySeason.map(arr => arr.length);
  const lengthOfLeapSeason = Math.max(...seasonLengths);
  const lengthOfNonLeapSeason = Math.min(...seasonLengths);
  if (lengthOfLeapSeason !== lengthOfNonLeapSeason) {
    if (indexOfLeapDay >= 0) {
      for (let i = 0; i < dataBySeason.length; i++) {
        const seasonArr = dataBySeason[i];
        if (seasonArr.length !== lengthOfLeapSeason) {
          seasonArr.splice(indexOfLeapDay, 0, seasonArr[indexOfLeapDay]);
        }
      }
    } else {
      for (let i = 0; i < dataBySeason.length; i++) {
        const seasonArr = dataBySeason[i];
        if (seasonArr.length === lengthOfLeapSeason) {
          seasonArr.splice(indexOfLeapDay, 1);
        }
      }
    }
  }
  
  // Calculate normals and construct obj of chart info for timeseries/normal/highest/lowest chart
  const dataByDate = transpose(dataBySeason).slice(0, newChartData.timeseries.dates.length);
  const normals = dataByDate.map(dateArr => dateArr.reduce((a,b) => a + b) / dateArr.length);
  newChartData.timeseries.normal = normals;
  newChartData.timeseries.highest.data = newChartData.timeseries.highest.data.slice(0, newChartData.timeseries.dates.length);
  newChartData.timeseries.lowest.data = newChartData.timeseries.lowest.data.slice(0, newChartData.timeseries.dates.length);

  return { newChartData, newCurrentSeasonChartData: null };
};


const TAB_NAME = 'Rainfall Extremes';
const DATA_NAME = 'pcpn';

const info = {
  name: TAB_NAME,
  subTabs: [{
    name: '>0.5 inches'
  },{
    name: '>1.0 inches'
  },{
    name: 'Longest Run of Dry Days'
  }],
  chartInfo: {
    chartSelectorId: 'rainfallExtremesChartSelector',
    charts: [{
      name: 'Daily Precipitation and Threshold Exceedance',
      showIfSubTab: ['>0.5 inches', '>1.0 inches'],
      func: (chartData, address, tabInfoContextState, ...rest) => calcDailyPrecipOptions(chartData, address, tabInfoContextState.tabsSharedState.rainfallExtremesThresholdSelector.value[0])
    },{
      name: '',
      showIfSubTab: ['>0.5 inches', '>1.0 inches'],
      func: (chartData, address, ...rest) => calcTimeseriesPrecipOptions(chartData, address)
    },{
      name: 'Occurrences In Season',
      showIfSubTab: ['>0.5 inches', '>1.0 inches'],
      func: (chartData, address, tabInfoContextState, ...rest) => calcAnnualPrecipOptions(chartData, address, tabInfoContextState.tabsSharedState.rainfallExtremesSeasonBoundsSelector.value)
    },{
      name: 'Longest Run of Dry Days Annually',
      showIfSubTab: ['Longest Run of Dry Days'],
      func: calcAnnualDryRunOptions
    }],
  },
  onChange: [{
    whenXChanges: ['selectedSubTab'],
    handleChange: ({ selectedSubTab }, _, { updateOverlayData }) => {
      const jsonFileName = selectedSubTab[0] === '>' ? `${selectedSubTab.slice(1,4)}_inches.json` : 'longest_dry_run.json';
      const jsonFileDataKey = 'departures';
      return updateOverlayFromR2(jsonFileName, jsonFileDataKey)
        .then(d => () => updateOverlayData(d));
    },
    showLoading: true
  },{
    whenXChanges: ['selectedLocation', 'selectedTab'],
    handleChange: ({ selectedTab, selectedLocation, pastLocations, rawChartData }, _, { setRawChartData, fetchingBaseData }) => {
      if (selectedTab === TAB_NAME && !fetchingBaseData) {
        let newRawChartData = { ...rawChartData };
        if (!(selectedLocation in newRawChartData)) {
          newRawChartData = { [selectedLocation]: {} };
        }
        
        if (!(DATA_NAME in newRawChartData[selectedLocation])) {
          const locInfo = pastLocations[selectedLocation];
          const coords = `${locInfo.lng},${locInfo.lat}`;

          const elems = {
            loc: coords,
            grid: 'ncei-clim',
            sdate: '1951-01-01',
            edate: format(TODAY, 'yyyy-MM-dd'),
            elems: [{
              name: 'pcpn'
            }]
          };

          return fetchFromAcis(elems)
            .then(rawData => {
              newRawChartData[selectedLocation][DATA_NAME] = rawData;
              return () => setRawChartData(newRawChartData);
            });
          }
      }
      
      return () => {};
    },
    showLoading: true
  },{
    whenXChanges: ['rainfallExtremesThresholdSelector', 'rainfallExtremesSeasonBoundsSelector', 'rawChartData', 'selectedSubTab'],
    handleChange: ({ selectedTab, selectedLocation, tabsSharedState, rawChartData }, _, { setChartData }) => {
      if (selectedTab === TAB_NAME && selectedLocation in rawChartData && DATA_NAME in rawChartData[selectedLocation]) {
        const { newChartData } = refreshRainfallExtremesChartData(
          rawChartData[selectedLocation][DATA_NAME],
          tabsSharedState.rainfallExtremesThresholdSelector.value,
          tabsSharedState.rainfallExtremesSeasonBoundsSelector.value
        );
        return () => setChartData(newChartData);
      }
      return () => {};
    },
    showLoading: true
  }],
  mapInfo: {
    overlayKeySelectorId: 'departureSelector',
    regionsSelectorId: 'regionSelector'
  },
  optionsPanel: {
    map: [{
      component: 'listSelector',
      id: 'rainfall-extremes-regions',
      useSharedState: 'regionSelector'
    },{
      component: 'departureSelector',
      id: 'rainfall-extremes-comparison',
      useSharedState: 'departureSelector'
    }],
    chart: [{
      component: 'listSelector',
      id: 'rainfall-extremes-charts',
      useSharedState: 'rainfallExtremesChartSelector',
      props: {
        listTypeSingular: 'Chart',
        listTypePlural: 'Charts',
        showAll: false,
        showNone: false
      }
    },{
      component: 'thresholdSelector',
      id: 'rainfall-extremes-threshold-selector',
      useSharedState: 'rainfallExtremesThresholdSelector',
      props: {
        columnHeading: 'Heavy Rain',
        minNum: 1,
        maxNum: 1,
        minValue: 0,
        maxValue: 100,
        step: 0.1,
        colors: ['black'],
        label: () => 'Change Threshold',
        showReset: true
      }
    },{
      component: 'seasonBoundsSelector',
      id: 'rainfall-extremes-season-bounds-selector',
      useSharedState: 'rainfallExtremesSeasonBoundsSelector',
    }]
  },
  componentFns: {
    title: ({selectedSubTab, display, overlayDataDate, chartData}) => {
      try {
        let sdate, edate;
        if (display === 'map') {
          const dateObj = parseISO(overlayDataDate);
          sdate = format(new Date(dateObj.getFullYear(), 0, 1), 'LLL do, yyyy');
          edate = format(parseISO(overlayDataDate), 'LLL do, yyyy');
        } else {
          sdate = format(parseISO(chartData.timeseries.dates[0]), 'LLL do, yyyy');
          edate = format(parseISO(chartData.timeseries.dates.slice(-1)[0]), 'LLL do, yyyy');
        }

        const type = selectedSubTab[0] === '>' ? `${selectedSubTab} Precipitation` : selectedSubTab;
        return {
          type,
          sdate,
          edate
        }
      } catch {
        return null;
      }
    }
  },
  textPanelInfo: []
};

export default info;
export const rainfallExtremesSharedStateDefinitions = {
  'rainfallExtremesChartSelector': {
    options: info.chartInfo.charts,
    initValue: info.chartInfo.charts
  },
  'rainfallExtremesThresholdSelector': {
    onNavChange: (oldValue, { newSubTab }) => {
      const newValue = parseFloat(newSubTab.slice(1,4));
      return isNaN(newValue) ? oldValue : [newValue];
    },
    initValue: ({ selectedSubTab }) => ([parseFloat(selectedSubTab.slice(1,4))])
  },
  'rainfallExtremesSeasonBoundsSelector': {
    persist: false,
    initValue: [`${TODAY.getFullYear()}-01-01`, format(TODAY, 'yyyy-MM-dd')]
  }
};