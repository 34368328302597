import { parseISO, format } from 'date-fns';

import calcTimeseriesOptions from "../../utilities/charts/timeseries-options";
import calcOnTodayValueAmountOptions from "../../utilities/charts/onToday-value-amount-options";
import calcThresholdsOptions from "../../utilities/charts/thresholds-options";
import updateOverlayFromR2 from '../../utilities/overlayUpdateFns';
import { refreshGddChartData } from '../../utilities/charts/chart-data-functions';
import { fetchFromAcis } from '../../utilities/acis-api';
import { TODAY } from '../../contexts/constants';

const addName = (chartData, address, tabInfoContextState, fn) => {
  const { tabsSharedState } = tabInfoContextState;
  const base = tabsSharedState.gddBaseSelector.value[0];
  return fn(chartData, address, `Base ${base} GDDs`);
};

const TAB_NAME = 'Degree Day Based Indicators';
const info = {
  name: TAB_NAME,
  subTabs: [{ 
      name: 'Base 50',
    },{ 
      name: 'Base 32',
  }],
  chartInfo: {
    chartSelectorId: 'gddChartSelector',
    charts: [{
      name: 'GDDs Accumulation Throughout Season',
      showIfSubTab: ['Base 50', 'Base 32'],
      func: (chartData, address, tabInfoContextState, ...rest) => addName(chartData, address, tabInfoContextState, calcTimeseriesOptions)
    },{
      name: 'Seasonal GDDs Achieved',
      func: (chartData, address, tabInfoContextState, ...rest) => addName(chartData, address, tabInfoContextState, calcOnTodayValueAmountOptions)
    },{
      name: 'Seasonal Date of Threshold Exceedance',
      func: (chartData, address, tabInfoContextState, ...rest) => addName(chartData, address, tabInfoContextState, calcThresholdsOptions)
    }],
  },
  onChange: [{
    whenXChanges: ['selectedSubTab'],
    handleChange: ({ selectedTab, selectedSubTab, overlayFilename }, _, { updateOverlayData }) => {
      if (selectedTab === TAB_NAME) {
        let jsonFileName;
        if (selectedSubTab.includes('Base')) {
          jsonFileName = `${selectedSubTab.split(' ').join('_').toLowerCase()}.json`;
        } else if (overlayFilename) {
          return () => {};
        } else {
          jsonFileName = 'base_50.json';
        }
        const jsonFileDataKey = 'departures';
        return updateOverlayFromR2(jsonFileName, jsonFileDataKey, undefined, true)
          .then(d => () => updateOverlayData(d));
      }
      return () => {};
    },
    showLoading: true
  },{
    whenXChanges: ['gddThresholdSelector', 'gddSeasonBoundsSelector', 'gddBaseSelector', 'rawChartData', 'selectedSubTab'],
    handleChange: ({ selectedTab, selectedLocation, tabsSharedState, rawChartData, pastLocations }, _, { setRawChartData, setChartData }) => {
      if (selectedTab === TAB_NAME && selectedLocation in rawChartData) {
        const baseNumber = tabsSharedState.gddBaseSelector.value[0];
        const targetBase = `gddBase${baseNumber}`;
        if (!(targetBase in rawChartData[selectedLocation])) {
          const locInfo = pastLocations[selectedLocation];
          const coords = `${locInfo.lng},${locInfo.lat}`;

          const elems = {
            loc: coords,
            grid: 'ncei-clim',
            sdate: '1951-01-01',
            edate: format(TODAY, 'yyyy-MM-dd'),
            elems: [{
              name: 'gdd',
              base: baseNumber
            }],
          };

          return fetchFromAcis(elems)
            .then(rawData => {
              const newRawChartData = { ...rawChartData };
              newRawChartData[selectedLocation][targetBase] = rawData;
              return () => setRawChartData(newRawChartData);
            });
        } else {
          const newChartData = refreshGddChartData(rawChartData[selectedLocation][targetBase], tabsSharedState.gddThresholdSelector.value, tabsSharedState.gddSeasonBoundsSelector.value, TODAY);
          return () => setChartData(newChartData);
        }
      }
      return () => {};
    },
    showLoading: true
  }],
  mapInfo: {
    overlayKeySelectorId: 'departureSelector',
    regionsSelectorId: 'regionSelector'
  },
  optionsPanel: {
    map: [{
      component: 'listSelector',
      id: 'gdds-regions',
      useSharedState: 'regionSelector'
    },{
      component: 'departureSelector',
      id: 'gdds-comparison',
      useSharedState: 'departureSelector'
    }],
    chart: [{
      component: 'listSelector',
      id: 'gdds-charts',
      useSharedState: 'gddChartSelector',
      props: {
        listTypeSingular: 'Chart',
        listTypePlural: 'Charts',
        showAll: false,
        showNone: false
      }
    },{
      component: 'thresholdSelector',
      id: 'gdd-thresholds-selector',
      useSharedState: 'gddThresholdSelector',
      props: {
        columnHeading: 'GDD',
        minNum: 0,
        maxNum: 5,
        minValue: 0,
        maxValue: Infinity,
        step: 100,
        initValue: 1000,
        colors: ['#bae4b3', '#74c476', '#238b45','#2c7745', '#275436']
      }
    },{
      component: 'seasonBoundsSelector',
      id: 'gdd-season-bounds-selector',
      useSharedState: 'gddSeasonBoundsSelector'
    },{
      component: 'thresholdSelector',
      id: 'gdd-base-selector',
      useSharedState: 'gddBaseSelector',
      props: {
        columnHeading: 'GDD',
        unit: 'Base',
        minNum: 1,
        maxNum: 1,
        minValue: -200,
        maxValue: 200,
        initValue: 'gddSelectedSubTab',
        colors: ['black'],
        label: (value, unit) => `GDD ${unit} ${value}`,
        automaticUpdateOnEdit: false,
        showReset: true
      }
    }]
  },
  componentFns: {
    title: ({selectedSubTab, display, overlayDataDate, overlayFilename, tabsSharedState, chartData}) => {
      try {
        let sdate, edate, base;
        if (display === 'map') {
          const dateObj = parseISO(overlayDataDate);
          sdate = format(new Date(dateObj.getFullYear(), 2, 1), 'LLL do, yyyy');
          base = overlayFilename.slice(0,-5).replace('_', ' ');
          base = base[0].toUpperCase() + base.slice(1);
          edate = format(dateObj, 'LLL do, yyyy');
        } else {
          sdate = format(parseISO(chartData.timeseries.dates[0]), 'LLL do, yyyy');
          edate = format(parseISO(chartData.timeseries.dates.slice(-1)[0]), 'LLL do, yyyy');
          base = typeof tabsSharedState.gddBaseSelector.value === 'function' ? selectedSubTab : `Base ${tabsSharedState.gddBaseSelector.value}`;
        }
  
        return {
          type: `${base} GDDs`,
          sdate,
          edate
        };
      } catch {
        return null;
      }
    }
  },
  textPanelInfo: [{
    name: 'Analysis',
    onlyShowIfChart: true,
    generateMarkdown: ({ chartData,  tabsSharedState }) => {
      try {
        const baseNumber = tabsSharedState.gddBaseSelector.value[0];
        // const targetBase = `gddBase${baseNumber}`;
        
        let sdate = tabsSharedState.gddSeasonBoundsSelector.value;
        sdate = sdate.slice(5, 10) + '-' + sdate.slice(0,4);
        const sGdd = 0;
        let edate = chartData.timeseries.dates.slice(-1)[0] + '-' + sdate.slice(-4);
        const eGdd = chartData.timeseries.current[chartData.timeseries.dates.length - 1];
        
        return `#  Success \n The madlib text for GDD Base ${baseNumber} will go here.\n\n The current season started on ${sdate} at ${sGdd} Base ${baseNumber} GDDs and ended on ${edate} at ${eGdd} Base ${baseNumber} GDDs`;
      } catch {
        return '';
      }
    }
  },{
    name: 'Local Links',
    onlyShowIfChart: true,
    generateMarkdown: ({ selectedLocation }) => {
      return `${selectedLocation.lng}, ${selectedLocation.lat} links: \n - [Duck Duck Go](https://duckduckgo.com) \n - [Google](https://google.com)`;
    }
  },{
    name: 'FAQ',
    markdownFilename: 'faq_default.md'
  }]
};

export default info;
export const gddsSharedStateDefinitions = {
  'gddChartSelector': {
    options: info.chartInfo.charts,
    initValue: info.chartInfo.charts
  },
  'gddThresholdSelector': {
    initValue: []
  },
  'gddSeasonBoundsSelector': {
    persist: false,
    initValue: [`${TODAY.getFullYear()}-03-01`, format(TODAY, 'yyyy-MM-dd')]
  },
  'gddBaseSelector': {
    onNavChange: (oldValue, { newSubTab }) => {
      let newValue = parseInt(newSubTab.slice(5));
      return isNaN(newValue) ? oldValue : [newValue];
    },
    initValue: ({ selectedSubTab }) => ([parseInt(selectedSubTab.slice(5))])
  },
}