/* eslint-disable import/no-webpack-loader-syntax */
import React, { useContext, useState, useCallback, useRef, useEffect } from 'react';

import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import Map, { Source, Layer } from 'react-map-gl';

import checkIfArraysContainSameValues from '../../utilities/compareArrays';

import { GlobalContext } from '../../contexts/global.context';

import { regionBBoxes, stateFips, stateFipsToUsps } from '../../contexts/constants';

import LocationPins from './location-pins/location-pins.component';
import Button from '../button/button.component';

import './map.styles.scss';

// eslint-disable-next-line @typescript-eslint/no-var-requires
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
// Set token for mapbox API
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;


// Bounding box used to set initial view
const INIT_BOUNDS = [-126, 34, -66, 43];

// Bounding box used to limit panning
const BBOX = {
  north: 53.3,
  south: 19.8,
  east: -54.0,
  west: -137.8,
};

// Clamps latitude
function isOutsideLatBounds(lat) {
  return lat > BBOX.north || lat < BBOX.south;
}

// Clamps longitude
function isOutsideLngBounds(lng) {
  return lng > BBOX.east || lng < BBOX.west;
}

// Gets the address name for a set of coordinates if it is within the list of allowed states
function getLocation(lat, lng, token) {
  if (isOutsideLatBounds(lat) || isOutsideLngBounds(lng)) {
    return false;
  }

  return fetch(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?limit=1&access_token=${token}`,
    { method: 'GET' }
  )
    .then((response) => response.json())
    .then((res) => {
      const feature = res.features[0];
      const countryCode =
        feature.context[feature.context.length - 1].short_code;
      if (countryCode !== 'us') {
        throw new Error('Out of bounds');
      }

      const addressParts = ['place', 'district', 'region'].reduce((address, addressPartName) => {
        const context = feature.context.find(({ id }) => id.includes(addressPartName));
        if (context) {
          address.push(context.text);
        }
        return address;
      }, []);

      return {
        id: String(Date.now()),
        address: addressParts.length > 0 ? addressParts.join(', ') : 'Unnamed Location',
        lat,
        lng,
      };
    })
    .catch(() => false);
}

const consolidateColorDefs = (colorData, regions) => {
  const defaultColor = 'rgba(0,0,0,0)';
  let colors = [
    'match',
    ['get', 'GEOID']
  ];
  
  if (Object.keys(colorData).length === 0 || regions.length === 0) {
    return defaultColor;
  }
  regions.forEach(regName => {
    if (colorData[regName] !== undefined) {
      colors = colors.concat(colorData[regName]);
    }
  });

  colors.push(defaultColor);
  return colors;
};

function HoverInfoDisplay({ hoverInfo }) {
  if (!hoverInfo) {
    return '';
  } else {
    return (
      <div className='map-hover-info' style={{ zIndex: 4, pointerEvents: 'none' }}>
        <div className='map-hover-info-name'>{hoverInfo.name}</div>
        <div className='map-hover-info-value'>{hoverInfo.value}</div>
      </div>
    );
  }
}

export default function MapComponent() {
  const mapRef = useRef(null);
  const [hoverInfo, setHoverInfo] = useState(null);
  const [viewState, setViewState] = useState({
    bounds: INIT_BOUNDS,
  });
  const [initView, setInitView] = useState(null);
  const [isInitView, setIsInitView] = useState(true);
  const [prevRegions, setPrevRegions] = useState([]);

  const { display, toggleDisplay, overlayData, overlayColors, getSelectorValueByIdFromTabInformation, pastLocations, handleUpdate } = useContext(GlobalContext);

  useEffect(() => {
    try {
      const currCenter = mapRef.current.getCenter();
      const currZoom = mapRef.current.getZoom();

      setIsInitView(
        currCenter.lat === initView.center[1] &&
          currCenter.lng === initView.center[0] &&
          currZoom === initView.zoom
      );
    } catch {
      setIsInitView(true);
    }
  }, [viewState, initView]);

  // Tries to get new location information, stores it if successful, moves map to new location
  const handleMapClick = async (e) => {
    const results = await getLocation(e.lngLat.lat, e.lngLat.lng, process.env.REACT_APP_MAPBOX_TOKEN);
    if (results) {
      const newPastLocs = {
        ...pastLocations,
        [results.id]: results,
      };

      handleUpdate('addLocation', { newId: results.id, newPastLocations: newPastLocs });

      if (mapRef.current) {
        mapRef.current.flyTo({
          center: [e.lngLat.lng, e.lngLat.lat],
          speed: 0.8,
          essential: true,
        });
      }
    }
  };

  // Tries to get new location information, stores it if successful, moves map to new location
  const handleZoomToBbox = async (regions) => {
    if (mapRef.current) {
      if (regions.length === 0 || regions.length === Object.keys(regionBBoxes).length) {
        resetViewState();
      } else {
        const bbox = regions.reduce((acc, regName) => {
          const bbox = regionBBoxes[regName];
          if (bbox[0][0] < acc[0][0]) acc[0][0] = bbox[0][0];
          if (bbox[0][1] < acc[0][1]) acc[0][1] = bbox[0][1];
          if (bbox[1][0] > acc[1][0]) acc[1][0] = bbox[1][0];
          if (bbox[1][1] > acc[1][1]) acc[1][1] = bbox[1][1];
          return acc;
        }, [[180,90],[-180,-90]]);
        mapRef.current.fitBounds(bbox, { padding: 30 });
      }
    }
  };

  // Limits panning to bbox coordinates
  const handlePanning = (view) => {
    if (isOutsideLatBounds(view.latitude)) {
      view.latitude = viewState.latitude;
    }

    if (isOutsideLngBounds(view.longitude)) {
      view.longitude = viewState.longitude;
    }

    setViewState((prev) => {
      return {
        ...prev,
        ...view,
      };
    });
  };

  // Increases zoom step and sets init zoom for reset button to use
  const handleLoad = (e) => {
    e.target.scrollZoom.setWheelZoomRate(1 / 100);

    const center = e.target.getCenter();
    setInitView({
      zoom: e.target.getZoom(),
      center: [center.lng, center.lat],
    });
  };

  // Moves map to initial view
  const resetViewState = () => {
    mapRef.current.flyTo({
      ...initView,
      speed: 0.8,
      essential: true,
    });
  };

  const onHover = useCallback((event, data, display) => {
    const hasFeatures = Boolean(display === 'map' && event.features && event.features[0]);
    const countyProperties = hasFeatures && event.features[0].properties;

    let value = null;
    if (hasFeatures) {
      const countyFips = countyProperties.GEOID;
      for (const regionObj of Object.values(data)) {
        if (countyFips in regionObj) {
          value = regionObj[countyFips] + '%';
          break;
        }
      }
    }
    
    if (hasFeatures && value !== null) {
      let name = '';
      if (countyProperties.STATEFP === '11') {
        name = 'District of Columbia';
      } else {
        let countySuffix = 'County';
        if (countyProperties.STATEFP === '22') {
          countySuffix = 'Parish';
        } else if ((countyProperties.STATEFP === '51' && parseInt(countyProperties.GEOID) > 51500) || ['24510', '29510', '32510'].includes(countyProperties.GEOID)) {
          countySuffix = 'City';
        }

        const stateUsps = stateFipsToUsps(countyProperties.STATEFP);
        name = `${countyProperties.NAME} ${countySuffix}, ${stateUsps}`;
      }
      
      setHoverInfo({
        name,
        value
      });
    } else {
      setHoverInfo(null);
    }
  }, []);

  const overlayKey = getSelectorValueByIdFromTabInformation('overlayKey');
  const selectedOverlayData = overlayData ? overlayData[overlayKey] : [];
  const selectedOverlayColors = overlayColors ? overlayColors[overlayKey] : [];
  const selectedRegions = getSelectorValueByIdFromTabInformation('regions');
  
  // Anytime the selected regions change, zoom to the selected ones
  if (selectedRegions && prevRegions && !checkIfArraysContainSameValues(selectedRegions, prevRegions)) {
    setPrevRegions(selectedRegions);
    handleZoomToBbox(selectedRegions);
  }

  return (
    <div className='map-container'>
      {display === 'map' && !isInitView &&
        <Button buttonType='resetZoom' onClick={resetViewState} style={{ zIndex: 4 }} >
          Reset Zoom
        </Button>
      }
      
      {display === 'map' && <HoverInfoDisplay hoverInfo={hoverInfo} />}

      <Map
        {...viewState}
        ref={mapRef}
        mapStyle='mapbox://styles/precipadmin/cl9isweo8005w14qs6gsnguyy'
        boxZoom={false}
        dragRotate={false}
        touchPitch={false}
        doubleClickZoom={false}
        attributionControl={false}
        onMove={(evt) => handlePanning(evt.viewState)}
        onMouseMove={(e) => onHover(e, selectedOverlayData, display)}
        onClick={(e) => {
          handleMapClick(e);
          if (display === 'map') toggleDisplay();
        }}
        onLoad={handleLoad}
        minZoom={3.1}
        interactiveLayerIds={['county-fill']}
        projection='mercator'
      >
        <LocationPins />

        <Source
          type='vector'
          url='mapbox://precipadmin.9quy0brw'
          id='county-colors'
        >
          <Layer
            id='county-fill'
            type='fill'
            source-layer='lower_48_counties_2023-5pti0g'
            paint={{
              'fill-color': consolidateColorDefs(selectedOverlayColors, selectedRegions),
              'fill-opacity': 0.5,
            }}
            filter={['in', ['get', 'STATEFP'], ['literal', stateFips]]}
          />
        </Source>
      </Map>
    </div>
  );
}
