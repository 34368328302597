import React from 'react';

import './header.styles.scss';

export default function Header() {
  function clearCache() {
    localStorage.clear();
    window.location.reload();
  }
  
  return (
    <header>
      <button className='clear-cache-btn' onClick={clearCache}>Clear Cache (For development only)</button>
      <div className='angled angle-1'></div>
      <div className='angled angle-2'></div>

      <h1>Climate Indicators for Agriculture</h1>
    </header>
  );
}