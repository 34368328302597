import { MenuItem, Select } from "@mui/material";

import Accordion from '../accordion/accordion.component';

export const defaultProps = {
  label: '',
  value: null,
  options: [],
};

export default function SelectSelector({
  expanded,
  handleChange,
  handleChangeSelection,
  id=null,
  label=defaultProps.label,
  value=defaultProps.value,
  options=defaultProps.options,
}) {
  return (
    <Accordion expanded={expanded === id} handleChange={handleChange} id={id} label={label} >
      <Select
        value={value}
        onChange={(e) => handleChangeSelection(e.target.value)}
        style={{ width: '100%' }}
      >
        {options.map(({ name, value }, i) => <MenuItem key={value + '-' + i} value={value}>{name}</MenuItem>)}
      </Select>
    </Accordion>
  );
}

export function BaseSelectSelector({
  handleChangeSelection,
  value=defaultProps.value,
  options=defaultProps.options,
}) {
  return (
    <Select
      value={value}
      onChange={(e) => handleChangeSelection(e.target.value)}
      style={{ width: '100%' }}
    >
      {options.map(({ name, value }, i) => <MenuItem key={value + '-' + i} value={value}>{name}</MenuItem>)}
    </Select>
  );
}