import React, { useContext } from 'react';

import { GlobalContext } from '../../../contexts/global.context';
import { TabInfoContext } from '../../../contexts/tab-info.context';

import './data-type-and-date.styles.scss';

export default function DataTypeAndDate() {
  const { display, selectedSubTab, overlayDataDate, overlayFilename, chartData } = useContext(GlobalContext);
  const { tabsSharedState, componentFns } = useContext(TabInfoContext);

  const title = (componentFns && 'title' in componentFns) ? componentFns.title({ selectedSubTab, display, overlayDataDate, overlayFilename, tabsSharedState, chartData }) : null;

  let dateLine = '';
  if (title) {
    if (title.dateline) {
      dateLine = title.dateline;
    } else if (!title.sdate) {
      dateLine = title.edate;
    } else if (!title.edate) {
      dateLine = title.sdate;
    } else {
      dateLine = `${title.sdate} - ${title.edate}`;
    }
  }

  return <div className='data-date-type-container'>
    {title ?
      <div>
        <h2 className='data-date-type-text'>{title.type}</h2>
        <h2 className='data-date-type-text'>{dateLine}</h2>
      </div>
      :
      ''
    }
  </div>;
}