import React, { useEffect, useState } from 'react';

import Button from '../../button/button.component';
import Accordion from '../../accordion/accordion.component';
import ThresholdRangeItem from './threshold-range-item/threshold-range-item.component';

import './threshold-range-selector.styles.scss';

const defaultProps = {
  value: [0,100],
  minValue: 0,
  maxValue: Infinity,
  step: 1,
  showReset: false,
  resetValues: [0,100],
  automaticUpdateOnEdit: true,
  unit: 'Threshold',
  label: () => 'Edit Threshold Range',
  maxLabel: 'Max',
  minLabel: 'Min'
};

export default function ThresholdSelector({
  id,
  expanded,
  handleChange,
  handleChangeSelection,
  value=defaultProps.value,
  minValue=defaultProps.minValue,
  maxValue=defaultProps.maxValue,
  step=defaultProps.step,
  showReset=defaultProps.showReset,
  resetValues=defaultProps.resetValues,
  automaticUpdateOnEdit=defaultProps.automaticUpdateOnEdit,
  unit=defaultProps.unit,
  label=defaultProps.label,
  maxLabel=defaultProps.maxLabel,
  minLabel=defaultProps.minLabel,
  resetBtnUnit=null
}) {
  const [shownMin, setShownLower] = useState(value[0]);
  const [shownMax, setShownUpper] = useState(value[1]);

  useEffect(() => {
    if (shownMin !== value[0]) setShownLower(value[0]);
    if (shownMax !== value[1]) setShownUpper(value[1]);
  }, [value]);
  
  const canSubmit = shownMin !== value[0] || shownMax !== value[1];
  const canReset = showReset && (shownMin !== resetValues[0] || shownMax !== resetValues[1]);

  const resetShownThresholds = () => {
    setShownLower(resetValues[0]);
    setShownUpper(resetValues[1]);
    handleChangeSelection([...resetValues]);
  };

  const editThresholds = (minOrMax, newValue) => {
    minOrMax === 'min' ? setShownLower(newValue) : setShownUpper(newValue);
    if (automaticUpdateOnEdit) {
      handleChangeSelection(minOrMax === 'min' ? [newValue, value[1]] : [value[0], newValue]);
    }
  };

  const submitEdits = () => {
    handleChangeSelection([shownMin, shownMax]);
  };

  return (
    <Accordion
      expanded={expanded === id}
      handleChange={handleChange}
      id={id}
      label={label()}
    >
      <div className='threshold-range-selector-content-container'>
          <div className='grid-container'>
            <div className='header-empty' />
            <div className='header-value'>{unit}</div>
            <div className='header-controls-container'>
              <div className='header-minus'>&#8722;</div>
              <div className='header-add'>+</div>
            </div>
            <div className='divider' />
            <div>{maxLabel}:</div>
            <ThresholdRangeItem
              value={shownMax}
              color='black'
              editThreshold={(newValue) => editThresholds('max', newValue)}
              step={step}
              min={Math.min(minValue, shownMin)}
              max={maxValue}
            />
            <div>{minLabel}:</div>
            <ThresholdRangeItem
              value={shownMin}
              color='black'
              editThreshold={(newValue) => editThresholds('min', newValue)}
              step={step}
              min={minValue}
              max={Math.min(maxValue, shownMax)}
            />
          </div>

        {(canReset || !automaticUpdateOnEdit) &&
          <div className='add-button-container'>
            {canReset && (
              <Button
                buttonType='centeredSmall'
                onClick={resetShownThresholds}
                style={{
                  margin: '12px auto'
                }}
              >Reset {resetBtnUnit || unit}</Button>
            )}

            {!automaticUpdateOnEdit && (
              <Button
                buttonType={canSubmit ? 'centeredSmall' : 'disabled'}
                onClick={submitEdits}
                disabled={!canSubmit}
                style={{
                  margin: '12px auto'
                }}
              >Submit</Button>
            )}
          </div>
        }
      </div>
    </Accordion>
  );
}

export { defaultProps };