import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { parseISO, addYears, subYears, addDays, subDays, formatISO, min, max } from 'date-fns';

import Accordion from '../accordion/accordion.component';

const defaultProps = {
  value: [new Date(), new Date()],
  label: 'Season Dates',
  pickerLabel: ['Start Date', 'End Date'],
  sdateProps: {
    minDate: (today, value) => max([addDays(subYears(parseISO(today), 1), 1), addDays(subYears(parseISO(value[1]), 1), 1)]),
    maxDate: (today, value) => subDays(parseISO(value[1]), 1)
  },
  edateProps: {
    minDate: (today, value) => addDays(parseISO(value[0]), 1),
    maxDate: (today, value) => min([subDays(addYears(parseISO(today), 1), 1), subDays(addYears(parseISO(value[0]), 1), 1)])
  },
  today: new Date()
};

export default function SeasonBoundsSelector({
  expanded,
  handleChange,
  handleChangeSelection,
  id,
  value=defaultProps.value,
  label=defaultProps.label,
  pickerLabel=defaultProps.pickerLabel,
  sdateProps=defaultProps.sdateProps,
  edateProps=defaultProps.edateProps,
  today=defaultProps.today
}) {
  if (today === null || today === undefined) return '';

  return (
    <Accordion expanded={expanded === id} handleChange={handleChange} id={id} label={label} >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <DatePicker
            views={['month', 'day']}
            label={pickerLabel[0]}
            value={parseISO(value[0])}
            onChange={(newValue) => handleChangeSelection([formatISO(newValue), value[1]])}
            slotProps={{ textField: { InputLabelProps: { shrink: true } } }}
            minDate={sdateProps.minDate(today, value)}
            maxDate={sdateProps.maxDate(today, value)}
          />
          <DatePicker
            views={['month', 'day']}
            label={pickerLabel[1]}
            value={parseISO(value[1])}
            onChange={(newValue) => handleChangeSelection([value[0], formatISO(newValue)])}
            slotProps={{ textField: { InputLabelProps: { shrink: true } } }}
            minDate={edateProps.minDate(today, value)}
            maxDate={edateProps.maxDate(today, value)}
          />
        </div>
      </LocalizationProvider>
    </Accordion>
  );
}

function BaseSeasonBoundsSelector({
  handleChangeSelection,
  value=defaultProps.value,
  pickerLabel=defaultProps.pickerLabel,
  sdateProps=defaultProps.sdateProps,
  edateProps=defaultProps.edateProps,
  today=defaultProps.today
}) {
  if (today === null || today === undefined) return '';

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <DatePicker
          views={['month', 'day']}
          label={pickerLabel[0]}
          value={parseISO(value[0])}
          onChange={(newValue) => handleChangeSelection([formatISO(newValue), value[1]])}
          slotProps={{ textField: { InputLabelProps: { shrink: true } } }}
          minDate={sdateProps.minDate(today, value)}
          maxDate={sdateProps.maxDate(today, value)}
        />
        <DatePicker
          views={['month', 'day']}
          label={pickerLabel[1]}
          value={parseISO(value[1])}
          onChange={(newValue) => handleChangeSelection([value[0], formatISO(newValue)])}
          slotProps={{ textField: { InputLabelProps: { shrink: true } } }}
          minDate={edateProps.minDate(today, value)}
          maxDate={edateProps.maxDate(today, value)}
        />
      </div>
    </LocalizationProvider>
  );
}

export { defaultProps, BaseSeasonBoundsSelector };